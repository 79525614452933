import { Box, Container, Typography } from '@material-ui/core'
import React from 'react'
import { oldWinnerStyles } from '../../../utils/hooks/useApplyStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const OldWinners = () => {
    const styles = oldWinnerStyles();
    const classes = styles();
    return (
        <Container maxWidth="lg">
            <Box className={classes.lWinner}>
                <Box className={classes.lWinnerText}>
                    <Typography variant="h2">
                    Celebrating Epic Events Since 2022
                    </Typography>
                    <Typography variant="body1">
                    From the best conferences to the most innovative event design, we’ve celebrated remarkable events since 2022. Discover success stories in Eventeer’s Hall of Fame.
                    </Typography>
                    <a href='/eventeer-awards-winners' className={classes.winnersCTA}>See Past Winners<ArrowForwardIcon /> </a>
                </Box>
                <Box className={classes.awLogoRow}>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688625canon-png1675688625.png" alt="Canon" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675680680tmobile-png1675680680.png" alt="T-mobile" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706884343ttcsi-png1706884343.png" alt="Trinidad and Tobago Coalition of Services" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886167lynx-background-png1706886167.png" alt="Lynx Exhibitions" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675685241isa-png1675685241.png" alt="ISA" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706884911acca-png1706884911.png" alt="ACCA" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew/8978/content/images/1675688024nhs-png1675688024.png" alt="NHS" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886456nlm-png1706886456.png" alt="Network of the National Library of Medicine" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886631eco-canada-png1706886631.png" alt="ECO Canada's Virtual Career Fair" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706886885feu-png1706886885.png" alt="Far Eastern University" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706893593ndss-png1706893593.png" alt="Living Well with Diabetes" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1707156193strategic-events-new-png1707156194.png" alt="CARA West" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706894294gm-png1706894294.png" alt="General Motors" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706902743goc-png1706902743.png" alt="Government of Canada" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/17069029171-lunch-png1706902917.png" alt="Lundbeck National Meeting" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706903336image-6-png1706903336.png" alt="Regnology 30th RegTech Convention" />
                    </Box>

                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706903563showpiece-shows-png1706903563.png" alt="Steelpointe Yacht & Charter Show/Wings, Wheels & Water" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904552barrier-breakers-png1706904552.png" alt="2nd Annual Break Into Law Conference" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706904993united-robotics-png1706904993.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905123maxwell-leadership-png1706905123.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905277cleveland-clinic-png1706905277.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905435etap-png1706905435.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905709university-of-sydney-png1706905709.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905867ravens-png1706905867.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706905994tcoe-complete-png1706905994.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706906110fijatec-png1706906110.png" alt="URG Tech Summit" />
                    </Box>
                    <Box className={classes.awlogobox}>
                        <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1706906572nestle-png1706906572.png" alt="URG Tech Summit" />
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default OldWinners