import React, {useState} from 'react';
import { Grid, Card, Container, Typography, Box, IconButton, Button } from '@material-ui/core';
import { cardsStyles, lookingUpStyles } from "../../../utils/hooks/useApplyStyles";
import CardContent from '../../Marketplace/Cards/CardContent'
//import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'react-multi-carousel/lib/styles.css';
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoaderCard from "../../Loader/LoaderCard";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import LazyLoad from 'react-lazyload';
import featureBox from '../../../assets/images/featureObj.svg';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 3000, min: 1280 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1279, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
    useTransform: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                swipeToSlide: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                swipeToSlide: true,

            }
        }
    ]
};


export default function FeatureEvents({ events, eventsFilters, setEventsFilters }) {

    const styles = cardsStyles();
    const styles2 = lookingUpStyles();
    const classes = { ...styles(), ...styles2() }
    const [open, setOpen] = useState(false);
    const openFeatureCTAPop = () => {
        setOpen(true);
    };
    const closeFeatureCTAPop = () => {
        setOpen(false);
    };


    return (
        <>
            <Box component="div" className={`${classes.homeSection} ${classes.homesectionGap}`} style={{overflow: 'hidden'}}>
                <Container maxWidth="lg" style={{ position: 'relative', zIndex: 0 }}>
                    <Box className={classes.featureCircleObj}>
                        <LazyLoad height={100}>
                            <img src={featureBox} alt="Feature BG" />
                        </LazyLoad> 
                    </Box>
                    <Box className={classes.themeHeader} mb={2} textAlign="center">
                        <Typography variant="h2" className={classes.viewmoreTitle}>
                            Featured Events
                        </Typography>
                        <Typography variant="body1">Find top conferences, career & education fairs, trade shows, and more.</Typography>
                    </Box>

                    <ul className={classes.eventTypeLinks}>
                        <li>
                            <Link
                                // to=""
                                id="all-featured-events"
                                className={eventsFilters.featured_type === "all-featured-events" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    featured_type: "all-featured-events",
                                })}
                                gutterBottom
                            >
                                All
                            </Link>
                        </li>
                        <li>
                            <Link
                                // to=""
                                id="free-featured-events"
                                className={eventsFilters.featured_type === "free-featured-events" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    featured_type: "free-featured-events",
                                })}
                                gutterBottom
                            >
                                Free
                            </Link>
                        </li>
                        <li>
                            <Link
                                // to=""
                                id="paid-featured-events"
                                className={eventsFilters.featured_type === "paid-featured-events" ? "active" : ""}
                                onClick={() => setEventsFilters({
                                    ...eventsFilters,
                                    featured_type: "paid-featured-events",
                                })}
                                gutterBottom
                            >
                                Paid
                            </Link>
                        </li>
                    </ul>
                    {events && events.data && events.data.current_page_count === 0 &&
                        <Box component="div" m={1} mb={3} className={classes.navBox} >

                            <div>
                                <h4>No events found for your search</h4>
                                <p>Unfortunately we can't match an event to your criteria. Try changing some filters to get more results.</p>
                            </div>

                        </Box>
                    }
                    {events && events.isLoading ?
                        <Grid container spacing={3} className={classes.homeCardWrap}>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                            <Grid item lg={3} sm={6} xs={12} >
                                <LoaderCard />
                            </Grid>
                        </Grid>
                    :
                        <Grid className={classes.homeCardWrap}>
                            <Slider {...settings} className="cardthemeSlider">
                                {events && events.data && events.data.data && events.data.data.map((event) => {
                                    return (
                                        <>
                                            <Card className={classes.homeSingles}>
                                                <CardContent event_data={event} />
                                            </Card>
                                        </>
                                    );
                                })}
                            </Slider>
                            <Box className={classes.pFeatureEvent}>
                                <Button variant="contained" color="primary" onClick={openFeatureCTAPop}>Feature Your Events</Button>
                            </Box>
                        </Grid>
                    }
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.FeatureCTAModalMain}
                        open={open}
                        onClose={closeFeatureCTAPop}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>                                
                            <Box component="div" className={classes.FeatureCTAModal}>
                                    <IconButton aria-label="delete" className={classes.margin} onClick={closeFeatureCTAPop} size="small">
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeajVoIeIYIzK8OZ8h4lgfi94m-fIYdt3GZ2D0iH1EI0-e7EA/viewform?embedded=true" width="640" height="570" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                            </Box>
                        </Fade>
                    </Modal>
                </Container>

            </Box>


        </>
    );
};
