import React, { useState, useEffect } from "react";
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Container,
  Typography,
  Chip,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import {
  navbarStyles,
  GreenChip,
  WhiteBtn,
  RedChip,
  ArchiveChip,
  onboardingStyles,
} from "../../utils/hooks/useApplyStyles";
import Logo from "../../assets/logo.svg";
//import Eventeer_logo from '../../assets/images/Eventeer_logo.svg';
//import Eventeer_logo2024 from '../../assets/images/eventeer logo- navbar.svg';
import Eventeer_logo2025 from "../../assets/Eventeer2025/eventeer-logo-4.svg";
import SearchbarHeader from "./Searchbar";
import EventDetailSearchbar from "./../EventDetail/EventDetailSearchbar";
import { Link, useLocation } from "react-router-dom";
import theme from "../../components/theme/basetheme/GradientvFairs";
import moment from "moment";
//import Backdrop from '@material-ui/core/Backdrop';
//import CloseIcon from '@material-ui/icons/Close';
import LoaderLines from "../Loader/LoaderLines";
import HomeSearch from "../HomePage/HomePageSections/HomeSearchbar";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
//import headerbgeventeer2024 from '../../assets/images/headerbgeventeer2024.png';
//import signupImage from '../../assets/images/signup-image.png'
//import signupDoneImage from '../../assets/images/signup-done.png'
import Cookies from "universal-cookie";

export default function NavigationBar({
  searchFilters,
  setSearchFilters,
  event_data,
}) {
  const styles = navbarStyles();
  const styles2 = onboardingStyles();
  const classes = { ...styles(), ...styles2() };
  let location = useLocation();

  const now = moment();
  const today_date = moment().subtract(0, "days").format("YYYY-MM-DD");

  const eventLocation = !!(
    event_data &&
    event_data.data &&
    event_data.data.data[0] &&
    event_data.data.data[0].location
  )
    ? event_data.data.data[0].location
    : "";
  const start_date = !!(
    event_data &&
    event_data.data &&
    event_data.data.data[0] &&
    event_data.data.data[0].start_date
  )
    ? moment(event_data.data.data[0].start_date).format("YYYYMMDD")
    : "";
  const start_time = !!(
    event_data &&
    event_data.data &&
    event_data.data.data[0] &&
    event_data.data.data[0].start_time
  )
    ? moment(event_data.data.data[0].start_time, ["HHmm"]).format("HHmm")
    : "";
  const time_zone = !!(
    event_data &&
    event_data.data &&
    event_data.data.data[0] &&
    event_data.data.data[0].time_zone
  )
    ? event_data.data.data[0].time_zone
    : "";

  const cookies = new Cookies();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [isVisible, setIsVisible] = useState(false);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.mobRightBtn}
    >
      <MenuItem>
        <Button
          variant="outlined"
          href="https://www.vfairs.com/contact-us/?utm_source=website&utm_medium=referral&utm_campaign=vfairs-discover-nav"
          target="_blank"
          color="primary"
          id="host-an-event"
          style={{ width: "100%" }}
        >
          host an event
        </Button>
      </MenuItem>
    </Menu>
  );

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const mobileScreenn = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        style={
          location &&
          location.pathname &&
          location.pathname.match("/eventeer-awards")
            ? { background: "#24160C" }
            : { boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)" }
        }
      >
        <Container maxWidth="xl">
          <Toolbar className={classes.innerGutterHeader}>
            {location &&
            location.pathname &&
            location.pathname.match("/eventeer-awards") ? (
              <Link to="/eventeer-awards/">
                <img
                  src={Eventeer_logo2025}
                  alt="Eventeer Awards 2025"
                  className={classes.eventeerLogoImg}
                />
              </Link>
            ) : (
              <Link to="/">
                <img src={Logo} alt="vFairs Discover" />
              </Link>
            )}
            <div className={classes.grow} />
            {location &&
              location.pathname &&
              location.pathname.match(/marketplace/) && (
                <SearchbarHeader
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                />
              )}
            {location &&
              location.pathname &&
              location.pathname.match(/events/) && <EventDetailSearchbar />}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* {location && location.pathname && (location.pathname === "/eventeer-awards" || location.pathname === "/eventeer-awards/" || location.pathname === "/eventeer-awards-winners" || location.pathname === "/eventeer-awards-winners/") &&
              <Link to="/eventeer-awards-nomination">
                <Button
                  className={classes.buyTicketBtn}
                  variant="containedPrimary"
                  color="primary"
                >
                  Nominate Now
                </Button>
              </Link>
              } */}
              {location &&
                location.pathname &&
                !location.pathname.match("/eventeer-awards") && (
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://www.vfairs.com/contact-us/?utm_source=website&utm_medium=referral&utm_campaign=vfairs-discover-nav"
                    target="_blank"
                    id="host-an-event"
                  >
                    host an event
                  </Button>
                )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="primary"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      {mobileScreenn && isVisible && (
        <AppBar
          position="fixed"
          style={
            location &&
            location.pathname &&
            location.pathname.match("/eventeer-awards")
              ? { zIndex: 9999, background: "#24160C" }
              : { zIndex: 9999, boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)" }
          }
          className={
            location && location.pathname && location.pathname.match(/events/)
              ? classes.shadow
              : ""
          }
        >
          <Container maxWidth="xl">
            <Toolbar className={classes.stickyheadertw}>
              {location &&
              location.pathname &&
              location.pathname.match("/eventeer-awards") ? (
                <Link to="/eventeer-awards/">
                  <img
                    src={Eventeer_logo2025}
                    alt="Eventeer Awards 2024"
                    className={classes.eventeerLogoImg}
                  />
                </Link>
              ) : (
                <Link to="/">
                  <img src={Logo} alt="vFairs Discover" />
                </Link>
              )}
              {/* {location &&
                location.pathname &&
                (location.pathname.match("/eventeer-awards") ||
                  location.pathname.match("/eventeer-awards-winners")) && (
                  <div
                    className={classes.sectionDesktop}
                    style={{ float: "right", marginLeft: "auto" }}
                  >
                    <Link to="/eventeer-awards-nomination">
                      <Button
                        className={classes.buyTicketBtn}
                        variant="containedPrimary"
                        color="primary"
                      >
                        Nominate Now
                      </Button>
                    </Link>
                  </div>
                )} */}
              {location &&
                location.pathname &&
                (location.pathname === "/" ||
                  location.pathname.match(/collections/)) && (
                  <>
                    <HomeSearch />
                    <div className={classes.sectionDesktop}>
                      <Button
                        variant="contained"
                        color="primary"
                        href="https://www.vfairs.com/contact-us/?utm_source=website&utm_medium=referral&utm_campaign=vfairs-discover-nav"
                        target="_blank"
                        id="host-an-event"
                      >
                        host an event
                      </Button>
                      {/* <Link to="/onboarding-signup">
                      <Button variant='contained' color="primary" className="eventcreatebtn" >Create Event</Button>
                    </Link> */}
                    </div>
                    <div className={classes.sectionMobile}>
                      <IconButton
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="primary"
                      >
                        <MoreIcon />
                      </IconButton>
                    </div>
                  </>
                )}

              {location &&
                location.pathname &&
                location.pathname.match(/marketplace/) && (
                  <>
                    <SearchbarHeader
                      searchFilters={searchFilters}
                      setSearchFilters={setSearchFilters}
                    />
                    <div className={classes.sectionDesktop}>
                      <Button
                        variant="contained"
                        color="primary"
                        href="https://www.vfairs.com/contact-us/?utm_source=website&utm_medium=referral&utm_campaign=vfairs-discover-nav"
                        target="_blank"
                        id="host-an-event"
                      >
                        host an event
                      </Button>
                    </div>
                    <div className={classes.sectionMobile}>
                      <IconButton
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="primary"
                      >
                        <MoreIcon />
                      </IconButton>
                    </div>
                  </>
                )}
              {location &&
                location.pathname &&
                location.pathname.match(/events/) && (
                  <Grid container className={classes.fixednavbarMain}>
                    <Grid item lg={7} md={12}>
                      <div className={classes.leftHeaderSt}>
                        {event_data && event_data.isLoading && (
                          <>
                            <LoaderLines />
                          </>
                        )}
                        {event_data &&
                          event_data.data &&
                          event_data.data.data[0] && (
                            <>
                              <Typography
                                color="textPrimary"
                                variant="h4"
                                component="p"
                              >
                                {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].app_title
                                  ? event_data.data.data[0].app_title.replace(
                                      /<[^>]*(>|$)|&amp;|&gt;/g,
                                      "&"
                                    )
                                  : ""}
                              </Typography>
                              {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].start_date &&
                                today_date &&
                                now &&
                                (event_data.data.data[0].start_date ===
                                  today_date ||
                                  now.isBetween(
                                    event_data.data.data[0].start_date,
                                    event_data.data.data[0].end_date,
                                    "day",
                                    "[]"
                                  )) && (
                                  <GreenChip
                                    color="primary"
                                    label="Live"
                                    size="small"
                                    style={{
                                      marginLeft: 10,
                                      borderRadius: "4px",
                                    }}
                                  />
                                )}
                              {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].start_date &&
                                now &&
                                now.isBefore(
                                  event_data.data.data[0].start_date,
                                  "day"
                                ) && (
                                  <Chip
                                    color="primary"
                                    variant="outlined"
                                    label="Upcoming"
                                    size="small"
                                    style={{
                                      marginLeft: 10,
                                      borderRadius: "4px",
                                    }}
                                  />
                                )}
                              {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].on_demand_start_date &&
                                event_data.data.data[0].on_demand_end_date &&
                                now &&
                                now.isBetween(
                                  event_data.data.data[0].on_demand_start_date,
                                  event_data.data.data[0].on_demand_end_date
                                ) && (
                                  <RedChip
                                    label="On-Demand"
                                    size="small"
                                    style={{
                                      marginLeft: 10,
                                      borderRadius: "4px",
                                    }}
                                  />
                                )}
                              {event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].start_date &&
                                now &&
                                now.isAfter(
                                  event_data.data.data[0].start_date,
                                  "day"
                                ) &&
                                !now.isBetween(
                                  event_data.data.data[0].on_demand_start_date,
                                  event_data.data.data[0].on_demand_end_date
                                ) &&
                                !now.isBetween(
                                  event_data.data.data[0].start_date,
                                  event_data.data.data[0].end_date,
                                  "day",
                                  "[]"
                                ) && (
                                  <ArchiveChip
                                    label="Archived"
                                    size="small"
                                    style={{
                                      marginLeft: 10,
                                      borderRadius: "4px",
                                    }}
                                  />
                                )}
                            </>
                          )}
                      </div>
                    </Grid>
                    <Grid item lg={5} className={classes.stHeadergridt}>
                      <div className={classes.rightHeaderSt}>
                        {event_data &&
                          event_data.data &&
                          event_data.data.data[0] &&
                          event_data.data.data[0].start_date &&
                          today_date &&
                          now &&
                          (event_data.data.data[0].start_date === today_date ||
                            now.isBefore(
                              event_data.data.data[0].start_date,
                              "day"
                            )) && (
                            <WhiteBtn
                              variant="contained"
                              color="default"
                              target="_blank"
                              rel="nofollow"
                              id="add-to-calendar-nav"
                              startIcon={<EventAvailableIcon />}
                              href={
                                "https://www.google.com/calendar/render?action=TEMPLATE&text=" +
                                event_data.data.data[0].app_title +
                                "&dates=" +
                                start_date +
                                "T" +
                                start_time +
                                "00Z/" +
                                start_date +
                                "T" +
                                start_time +
                                "00Z&ctz=" +
                                time_zone +
                                "&details=" +
                                event_data.data.data[0].app_title +
                                "&location=" +
                                eventLocation +
                                "&sf=true&output=xml"
                              }
                            >
                              add to calendar
                            </WhiteBtn>
                          )}
                        {event_data &&
                          event_data.data &&
                          event_data.data.data[0] &&
                          event_data.data.data[0].application &&
                          event_data.data.data[0].application.url &&
                          event_data.data.data[0]
                            .is_url_visible_when_archieved &&
                          event_data.data.data[0].start_date &&
                          today_date &&
                          now &&
                          (event_data.data.data[0]
                            .is_url_visible_when_archieved === "1" ||
                            event_data.data.data[0].start_date === today_date ||
                            now.isBefore(
                              event_data.data.data[0].start_date,
                              "day"
                            ) ||
                            now.isBetween(
                              event_data.data.data[0].on_demand_start_date,
                              event_data.data.data[0].on_demand_end_date
                            )) && (
                            <Button
                              className={classes.buyTicketBtn}
                              variant="containedPrimary"
                              color="primary"
                              target="_blank"
                              href={
                                event_data.data.data[0].app_id &&
                                event_data.data.data[0].app_id === "8259"
                                  ? "https://canonrobotics.vfairs.com/en/canon-usa"
                                  : event_data.data.data[0].app_id === "8716"
                                  ? "https://efinancialcareers_fair_march.vfairs.com/en/registration?utm_campaign=JS_GLOBAL_MAR_2023_OTHER&utm_source=GLOBAL_OTHER_ENG&utm_medium=DA_VCE_OTHER_ENG"
                                  : event_data.data.data[0].app_id === "9186"
                                  ? "https://recruiterhub.efinancialcareers.com/Global-Fintech-Fair2023_EMEA-US_VFAIRS_ENG.html"
                                  : event_data.data.data[0].app_id === "9345"
                                  ? "https://recruiterhub.efinancialcareers.com/Financial-Services-Tech-VCE-Staffing_VFAIRS.html"
                                  : event_data.data.data[0].app_id === "8927"
                                  ? "https://www.filmscapechicago.com/"
                                  : "//" +
                                    event_data.data.data[0].application.url
                              }
                              id="event-link-nav"
                            >
                              {!!(
                                event_data &&
                                event_data.data &&
                                event_data.data.data[0] &&
                                event_data.data.data[0].main_button_label
                              ) &&
                              today_date &&
                              now &&
                              (event_data.data.data[0].start_date ===
                                today_date ||
                                now.isBefore(
                                  event_data.data.data[0].start_date,
                                  "day"
                                ))
                                ? event_data.data.data[0].main_button_label
                                : "Visit Website"}
                            </Button>
                          )}
                        <Button
                          variant="outlined"
                          color="primary"
                          href="https://www.vfairs.com/contact-us/?utm_source=website&utm_medium=referral&utm_campaign=vfairs-discover-nav"
                          target="_blank"
                          id="host-an-event"
                        >
                          host an event
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                )}
            </Toolbar>
          </Container>
        </AppBar>
      )}
      {renderMobileMenu}
    </div>
  );
}
