import { Box, Container, Chip } from '@material-ui/core'
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { awardWinnerCardStyles } from '../../../utils/hooks/useApplyStyles';
import AwardWinnerCard2024 from './AwardWinnerCard'
import AwardWinnerCard2023 from '../../WinnerPage/AwardWinnerCard';
import AwardWinnerCard2022 from '../../WinnerPage/AwardWinnerCard2022';
import OldWinners from './OldWinners';
import OldWinners2023 from '../../WinnerPage/OldWinners';

const AwardWinners = () => {
    const styles = awardWinnerCardStyles();
    const classes = styles();

    const [filters, setFilters] = useState({
        winners: "all",
    })

    return (
        <Box className={classes.awwinnerMai}>            
            <Container maxWidth="lg" >
            <section className={classes.filtersSection}>
                <Chip
                    variant={filters.winners === "all" ? "default" : "outlined"}
                    color="primary"
                    label="All"
                    size="medium"
                    className={classes.filterChip}
                    clickable={true}
                    onClick={() => setFilters({
                        ...filters,
                        winners: "all",
                    })}
                />
                <Chip
                    variant={filters.winners === "winners2024" ? "default" : "outlined"}
                    color="primary"
                    label="2024 winners"
                    size="medium"
                    className={classes.filterChip}
                    clickable={true}
                    onClick={() => setFilters({
                        ...filters,
                        winners: "winners2024",
                    })}
                />            
                <Chip
                    variant={filters.winners === "winners2023" ? "default" : "outlined"}
                    color="primary"
                    label="2023 Winners"
                    size="medium"
                    className={classes.filterChip}
                    clickable={true}
                    onClick={() => setFilters({
                        ...filters,
                        winners: "winners2023",
                    })}
                />
                <Chip
                    variant={filters.winners === "winners2022" ? "default" : "outlined"}
                    color="primary"
                    label="2022 Winners"
                    size="medium"
                    className={classes.filterChip}
                    clickable={true}
                    onClick={() => setFilters({
                        ...filters,
                        winners: "winners2022",
                    })}
                />
            </section>
                {filters && filters.winners && filters.winners === "all" &&
                    <>
                        <h2>2024 Winners</h2>
                        <AwardWinnerCard2024 />
                        <h2>2023 Winners</h2>
                        <AwardWinnerCard2023 />
                        <h2>2022 Winners</h2>
                        <AwardWinnerCard2022 />
                    </>
                }
                {filters && filters.winners && filters.winners === "winners2024" &&
                    <>
                        <h2>2024 Winners</h2>                    
                        <AwardWinnerCard2024 />
                        <OldWinners />
                    </>
                }
                {filters && filters.winners && filters.winners === "winners2023" &&
                    <>
                    <h2>2023 Winners</h2>   
                    <AwardWinnerCard2023 />
                    <OldWinners2023 />
                    </>
                }
                {filters && filters.winners && filters.winners === "winners2022" &&
                    <>
                        <h2>2022 Winners</h2>   
                        <AwardWinnerCard2022 />
                    </>
                }
            </Container>
        </Box>
    )
}

export default AwardWinners