import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//import { Helmet } from "react-helmet";
import NavigationBar from "../Header/Navbar";
import ResultsPageBanner from '../Banners/ResultsBanner'
import CardsWrapper from './CardsWrapper'
import DiscoverFooter from '../Footer/Footer'
//import og_MarketplaceImage from '../../assets/images/discover-marketplace-min.png';
import FeatureCTASec from '../Footer/FeatureCTASec';
import ScrollToTop from '.././../components/ScrollToTop';

export default function MarkePlacetMain() {
  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: window.location.href,
      title: "Marketplace"
    }
  });

  const search = useLocation().search;
  const getSearchQuery = new URLSearchParams(search).get('search');

  const [searchFilters, setSearchFilters] = useState({
    searchTerm: getSearchQuery ? getSearchQuery : "",
  })
  return (
    <div className="application">
      {/* <Helmet>
        <title>Find Virtual & Hybrid Events Around the World</title>
        <meta name="description" content="Search for events to attend, sponsor, and exhibit at. vFairs Discover brings the best in-person, hybrid, & virtual events under one roof." />
        <meta property="url" content={window.location.origin + window.location.pathname} />
        <meta property="title" content="Find Virtual & Hybrid Events Around the World" />
        <meta name="robots" content="index,follow"/>
        <meta property="og:title" content="Find Virtual & Hybrid Events Around the World" />
        <meta property="image" content={og_MarketplaceImage} />
        <meta property="og:image" content={og_MarketplaceImage} />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        <meta property="og:url" content={window.location.origin + window.location.pathname} />
        <meta property="og:site_name" content="vFairs Discover" />
        <meta property="og:description" content="Search for events to attend, sponsor, and exhibit at. vFairs Discover brings the best in-person, hybrid, & virtual events under one roof." />
      </Helmet> */}
      <ScrollToTop />
      <NavigationBar searchFilters={searchFilters} setSearchFilters={setSearchFilters} />
      <ResultsPageBanner searched={searchFilters.searchTerm} />
      <CardsWrapper searchTerm={searchFilters.searchTerm} />
      <FeatureCTASec />
      <DiscoverFooter />
    </div>
  );
}