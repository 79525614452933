import { Box, Typography, Grid } from '@material-ui/core';
import React from 'react'
import { awardWinnerCardStyles } from '../../utils/hooks/useApplyStyles';
//import OpenInNewIcon from '@material-ui/icons/OpenInNew';
//import winnerImage from '../../assets/images/box.png'
//import dummyOrg from '../../assets/dummy-org.png'
import winTagIcon from '../../assets/images/winnertag-icon.svg'

const AwardWinnerCard = () => {
    const styles = awardWinnerCardStyles();
    const classes = styles();
    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733843552lobby-32-1-1-jpg1733843552.jpg" alt="Virtual Bocconi and Jobs" />
                        </Box>                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best University Event</Typography>
                        <Typography variant="h2">Virtual Bocconi and Jobs</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733843568virtual-bocconi-and-jobs-jpg1733843568.jpg" alt="Virtual Bocconi and Jobs" />
                                </Box>
                                <Typography variant="body1">Bocconi University</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733927710lobby-28-1-1-jpg1733927710.jpg" alt="UK University Search" />
                        </Box>                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Virtual Education Fair</Typography>
                        <Typography variant="h2">UK University Search</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733927738uk-university-search-jpg1733927738.jpg" alt="UK University Search" />
                                </Box>
                                <Typography variant="body1">UK University Search</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733927878lobby-11-1-1-jpg1733927878.jpg" alt="UCCD Career Expo" />
                        </Box>                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Multi-Employer Job Fair</Typography>
                        <Typography variant="h2">UCCD Career Expo</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733927864uccd-university-centers-for-career-development-the-american-university-in-cairo-jpg1733927864.jpg" alt="UCCD Career Expo" />
                                </Box>
                                <Typography variant="body1">The American University in Cairo</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928034lobby-100-1-jpg1733928034.jpg" alt="The Manila Rotary Presidential Conference 2021" />
                        </Box>                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Virtual Conference</Typography>
                        <Typography variant="h2">The Manila Rotary Presidential Conference 2021</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928051the-manila-rotary-presidential-conference-2023-jpg1733928051.jpg" alt="The Manila Rotary Presidential Conference 2021" />
                                </Box>
                                <Typography variant="body1">Rotary International</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928146lobby-37-1-jpg1733928146.jpg" alt="Sci Fest" />
                        </Box>                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best 3D Virtual Designs</Typography>
                        <Typography variant="h2">Sci Fest</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928156usa-science-engineering-festival-jpg1733928156.jpg" alt="Sci Fest" />
                                </Box>
                                <Typography variant="body1">USA Science & Engineering Festival</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928220lobby-38-1-1-jpg1733928220.jpg" alt="Rothkoff 4th Annual Symposium" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Healthcare Event</Typography>
                        <Typography variant="h2">Rothkoff 4th Annual Symposium</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928232rothkoff-law-group-jpg1733928232.jpg" alt="Rothkoff 4th Annual Symposium" />
                                </Box>
                                <Typography variant="body1">Rothkoff Law Group</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928319lobby-2022-01-21t193601-002-1-jpg1733928319.jpg" alt="IMS Shoutout" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Out of the Box</Typography>
                        <Typography variant="h2">IMS Shoutout</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928306ims-png1733928306.png" alt="IMS Shoutout" />
                                </Box>
                                <Typography variant="body1">IMS Group</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928464lobby-25-1-jpg1733928464.jpg" alt="IAUG Wired 2021" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Telecom Event</Typography>
                        <Typography variant="h2">IAUG Wired 2021</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928416iaug-logo-jpg1733928416.jpg" alt="IAUG Wired 2021" />
                                </Box>
                                <Typography variant="body1">Innovatis Group</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928586exhibit-hall-17-1-jpg1733928586.jpg" alt="Holcim Business Summit 2021" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Agency Event</Typography>
                        <Typography variant="h2">Holcim Business Summit 2021</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928557holcim-logo-jpg1733928557.jpg" alt="Holcim Business Summit 2021" />
                                </Box>
                                <Typography variant="body1">Archiestic Events Production</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928660lobby-41-1-jpg1733928660.jpg" alt="Himel Digital Customer Meet" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best in Class Hybrid Event</Typography>
                        <Typography variant="h2">Himel Digital Customer Meet</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733928683himel-jpg1733928683.jpg" alt="Himel Digital Customer Meet" />
                                </Box>
                                <Typography variant="body1">Himel</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943347lobby-7-1-jpg1733943347.jpg" alt="Harbor Wholesale Spring Show" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Virtual Food Show</Typography>
                        <Typography variant="h2">Harbor Wholesale Spring Show</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943357harbor-wholesale-jpg1733943357.jpg" alt="Harbor Wholesale Spring Show" />
                                </Box>
                                <Typography variant="body1">Harbor Wholesale</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943460lobby-14-1-jpg1733943460.jpg" alt="GameX" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best IT Event</Typography>
                        <Typography variant="h2">GameX</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943430epiphany-pakistan-jpg1733943430.jpg" alt="GameX" />
                                </Box>
                                <Typography variant="body1">Epiphany</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943530lobby-21-1-jpg1733943530.jpg" alt="Experience ISU" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Virtual Open Day</Typography>
                        <Typography variant="h2">Experience ISU</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943541idaho-state-university-jpg1733943541.jpg" alt="Experience ISU" />
                                </Box>
                                <Typography variant="body1">Idaho State University</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943606lobby-12-1-jpg1733943606.jpg" alt="Cultivating Your Disability Workforce" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Non-profit Event</Typography>
                        <Typography variant="h2">Cultivating Your Disability Workforce</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943634national-disability-practitioners-1-jpg1733943634.jpg" alt="Cultivating Your Disability Workforce" />
                                </Box>
                                <Typography variant="body1">National Disability Practitioners</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943694lobby-45-1-1-jpg1733943694.jpg" alt="City Expo Malaysia" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Exhibition Experience</Typography>
                        <Typography variant="h2">City Expo Malaysia</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943706city-expo-malaysia-1-jpg1733943706.jpg" alt="City Expo Malaysia" />
                                </Box>
                                <Typography variant="body1">City Expo Malaysia</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943783lobby-19-1-1-jpg1733943783.jpg" alt="Bell Student Recruitment Experience" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Single-Employer Career Fair</Typography>
                        <Typography variant="h2">Bell Student Recruitment Experience</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943794bell-logo-jpg1733943794.jpg" alt="Bell Student Recruitment Experience" />
                                </Box>
                                <Typography variant="body1">Bell Canada</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943865lobby-34-1-jpg1733943865.jpg" alt="AM Industry Summit" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Use of Networking Technology</Typography>
                        <Typography variant="h2">AM Industry Summit</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943888asme-logo-jpg1733943888.jpg" alt="AM Industry Summit" />
                                </Box>
                                <Typography variant="body1">ASME</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733943960lobby-8-1-jpg1733943960.jpg" alt="Adventist Virtual Global Camp Meeting 2021" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best High Volume Events</Typography>
                        <Typography variant="h2">Adventist Virtual Global Camp Meeting 2021</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/17339439747th-day-adventist-church-jpg1733943974.jpg" alt="Adventist Virtual Global Camp Meeting 2021" />
                                </Box>
                                <Typography variant="body1">7th Day Adventist Church</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733944052lobby-50-1-jpg1733944052.jpg" alt="21st Grand Reunion and Convention" />
                        </Box>
                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Use of Gamification</Typography>
                        <Typography variant="h2">21st Grand Reunion and Convention</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733944064up-alumni-association-in-america-1-jpg1733944064.jpg" alt="21st Grand Reunion and Convention" />
                                </Box>
                                <Typography variant="body1">UP Alumni Association in America</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={4} xs={12} className={classes.awWinMain}>
                <Box className={classes.awWin}>
                    <Box className={classes.awWinT}>
                        <Box className={classes.awWinImg}>
                            <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/1733944135lobby-8-1-1-jpg1733944135.jpg" alt="2021 Land N Sea Marine & RV Virtual Dealer Trade Show" />
                        </Box>                        
                    </Box>
                    <Box className={classes.ChipawWinText}>
                        <Typography variant="body1"><img src={winTagIcon} alt="WinnerIn" />Best Virtual Trade Show</Typography>
                        <Typography variant="h2">2021 Land N Sea Marine & RV Virtual Dealer Trade Show</Typography>
                        <Box className={classes.awWinOrg}>
                            <Box className={classes.awWinOrgTitle}>
                                <Box className={classes.awWinIcon}>
                                    <img src="https://vepimg.b8cdn.com/uploads/vjfnew//content/images/17339441462021-land-n-sea-marine-rv-virtual-dealer-trade-show-1-jpg1733944146.jpg" alt="2021 Land N Sea Marine & RV Virtual Dealer Trade Show" />
                                </Box>
                                <Typography variant="body1">Land N Sea Distributing Inc. </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default AwardWinnerCard