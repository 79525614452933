// import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import colors from '../../components/theme/Colors';
// import { Badge } from '@material-ui/core';
// import theme from "../../components/theme/basetheme/GradientvFairs";
import { Chip, Button } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import browse_by_topic_bg from "../../assets/brows-by-topic-bg.svg";
import newsletterBG from "../../assets/images/bg-2-featured.png";
import honorbg from "../../assets/images/honor-bg.png";
import honornewbg from "../../assets/images/honornewbg.png";
//import headerbgeventeer2024 from '../../assets/images/headerbgeventeer2024.png';
import headerbgeventeer2025 from "../../assets/Eventeer2025/header-element.png";
import winnerbg from "../../assets/images/winner-get.png";
import winnerbg2024 from "../../assets/images/eventeerstarimg.png";
import nomineeTitleImg from "../../assets/images/nominee-title.png";
import nomineeTitleImg2024 from "../../assets/images/categories-page-header.png";
import nomineeBanner from "../../assets/images/nominee-banner.png";
import nomineeBanner2024 from "../../assets/images/header-img-nominee-details-page.png";
import winnerBanner from "../../assets/images/winner-banner.jpg";
import winnerBanner2024 from "../../assets/images/Winner2024bg.png";
//import nomineeHeaderBG from '../../assets/images/header-nomineebg.png';
import edetailbg from "../../assets/images/edetailbg.png";
import { nodeName } from "jquery";

export const cardsStyles = () => {
  return makeStyles((theme) => ({
    filderButton: {
      marginTop: 20,
      "& button": {
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "26px",
        textAlign: "center",
        textDecorationLine: "underline",
        textTransform: "capitalize",
        color: "#6D7A84",
        "&:hover": {
          textDecorationLine: "underline",
        },
      },
    },
    filterHeadingReset: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& button": {
        textTransform: "capitalize",
        padding: 0,
      },
    },
    shareNfollow: {
      position: "absolute",
      top: 10,
      right: 10,
      "& a": {
        display: "inline-block",
        margin: "0 3px",
      },
    },
    gridContainer: {
      maxWidth: " 90rem",
      margin: " 1.5rem auto 0px",
      padding: "0px 3.75rem 1.5rem",
    },
    filterSidebar: {
      [theme.breakpoints.up("md")]: {
        width: "19.5rem",
        marginRight: "1.5rem",
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: 20,
      },
    },
    listingBox: {
      display: "flex",
      flexDirection: "column",
    },
    mainSection: {
      display: "grid",
      gridTemplateColumns: " auto 1fr",
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "unset",
        padding: "0 20px",
        justifyContent: "center",
      },
    },
    boxCardWrap: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(19.5rem, 1fr))",
      gap: "1.5rem",
      justifyItems: "center",
      // display: 'flex',
      // flexWrap: 'wrap',
      // justifyContent: 'flex-start',
    },
    moreCardWrap: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-evenly",
      },
    },
    FeatureCTAModal: {
      background: "#FFFFFF",
      position: "relative",
      borderRadius: 12,
      padding: "0 24px",
      top: 32,
      "& iframe": {
        maxWidth: "100%",
      },
      "& button": {
        position: "absolute",
        right: 3,
        top: 3,
        "& svg": {
          color: "#505B63",
        },
      },
    },
    FeatureCTAModalMain: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    // boxGrid:{
    //   display: 'grid',
    //   gridTemplateColumns: 'repeat(auto-fill, minmax(19.5rem, 1fr))',
    // gap: '1.5rem',
    // justifyItems: 'center',
    // },
    cardList: {
      position: "relative",
      boxShadow: "0 20px 24px 0 rgba(0,0,0,0.12)",
      overflow: "visible",
      borderRadius: "12px",
      transition: "0.4s",
      marginBottom: 20,
      width: "100%",
      display: "flex",
      flexWrap: "nowrap",
      border: "1px solid #CCD5DC",
      padding: 16,
      boxShadow: "none",
      "& > div:first-child": {
        width: "38%",
        borderRadius: "12px",
      },
      "& > div + div": {
        width: "62%",
        border: 0,
      },
      "& >  div + div > div:last-child ": {
        [theme.breakpoints.up("sm")]: {
          width: "100%",
        },
      },
      "& .bottom_chips": {
        maxWidth: "100%",
      },
      "&:hover": {
        boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
      },
    },
    card: {
      // width: '280px',
      display: "flex",
      flexDirection: "column",
      minHeight: 393,
      backfaceVisibility: "hidden",
      transitionProperty: "box-shadow",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
      maxWidth: 342,
      minWidth: "100%",
      // boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
      boxShadow: "none",
      position: "relative",
      // overflow: 'visible',
      borderRadius: "1rem",
      transition: "0.4s",
      // marginBottom: 20,
      // marginRight: 12,
      borderRadius: "12px",
      [theme.breakpoints.down("md")]: {
        // maxWidth: '40%',
      },
      [theme.breakpoints.down("sm")]: {
        // maxWidth: '48%',
        // marginRight: 5,
      },

      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: 0,
        display: "block",
        width: "100%",
        bottom: -1,
        height: "100%",
        borderRadius: "12px",
        // backgroundColor: 'rgba(0,0,0,0.08)',
      },
      "&:hover": {
        boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
        "& div > a": {
          textDecoration: "underline",
        },
      },
    },
    homeSingles: {
      maxWidth: 342,
      // maxWidth: '23.1%',
      // width: '33%',
      position: "relative",
      // boxShadow: '0 5px 0 0 rgba(0,0,0,0.06)',
      // boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)',
      boxShadow: "none",

      overflow: "visible",
      borderRadius: "12px",
      transition: "0.4s",
      marginBottom: 20,
      marginRight: 10,
      marginLeft: 10,
      width: "auto !important",
      // minHeight: 350,
      [theme.breakpoints.down("md")]: {
        maxWidth: 280,
      },
      [theme.breakpoints.down("sm")]: {
        width: 325,
        maxWidth: "unset",
        marginRight: 5,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: 0,
        display: "block",
        width: "100%",
        bottom: -1,
        height: "100%",
        borderRadius: "1rem",
      },
      "& div + div > div.bottom_chips:last-child": {
        // overflow: 'hidden',
        // width: 240,
        width: "100%",
        maxWidth: "100%",
        minHeight: 50,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      "&:hover": {
        boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
      },
    },
    eventFormatSingles: {
      maxWidth: 310,
      // maxWidth: '23.1%',
      // width: '33%',
      position: "relative",
      overflow: "visible",
      borderRadius: "12px",
      transition: "0.4s",
      marginBottom: 15,
      marginRight: 10,
      marginLeft: 10,
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2);",
      marginTop: "15px",

      [theme.breakpoints.down("md")]: {
        maxWidth: 280,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: 325,
        maxWidth: "unset",
        marginRight: 5,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        margin: "auto",
        marginBottom: 12,
      },
      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: 0,
        display: "block",
        width: "100%",
        bottom: -1,
        height: "100%",
        borderRadius: "1rem",
        // backgroundColor: 'rgba(0,0,0,0.08)',
      },
      "& a:hover": {
        textDecoration: "none",
      },
    },
    cardSingles: {
      // maxWidth: 280,
      // maxWidth: '23.1%',
      // width: '33%',
      minHeight: 393,
      position: "relative",
      boxShadow: "0 8px 24px 0 rgba(0,0,0,0.12)",
      overflow: "visible",
      borderRadius: "1rem",
      transition: "0.4s",
      marginBottom: 20,
      // marginRight: 20,
      [theme.breakpoints.down("md")]: {
        // maxWidth: '34%',
      },
      [theme.breakpoints.down("sm")]: {
        // width: 325,
        maxWidth: "unset",
        // marginRight: 5,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        marginRight: 0,
      },
      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: 0,
        display: "block",
        width: "100%",
        bottom: -1,
        height: "100%",
        borderRadius: "1rem",
        // backgroundColor: 'rgba(0,0,0,0.08)',
      },
    },
    main: {
      overflow: "hidden",
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      zIndex: 1,
    },
    contentMain: {
      height: "100%",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        display: "block",
        width: "100%",
        height: "100%",
        background: "linear-gradient(to top, rgb(0 0 0 / 52%), rgba(0,0,0,0))",
      },
    },
    content: {
      position: "absolute",
      top: 0,
      width: "100%",
      zIndex: 1,
      padding: "15px 12px",
      textAlign: "left",
      // height:'100%'
    },
    hybridContent: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      zIndex: 1,
      padding: "15px 12px",
      textAlign: "left",
    },
    sponshopshipOepn: {
      background:
        "linear-gradient(360deg, #EDEDED 0%, #DFDFDF 32.81%, #DBDBDB 70.31%, #F4F4F4 84.38%, #D3D3D3 100%)",
      borderRadius: "0px 0px 8px 8px",
      width: 133,
      height: 22,
      position: "absolute",
      top: 0,
      right: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        fontSize: 11,
        lineHeight: "11px",
        color: "#000",
        fontWeight: 400,
        marginLeft: 4,
      },
      [theme.breakpoints.down("sm")]: {
        right: 4,
      },
    },
    tag: {
      display: "inline-block",
      backgroundColor: "#EF4876",
      borderRadius: "0.5rem",
      padding: "2px 0.5rem",
      color: "#fff",
      marginBottom: "0.5rem",
      textTransform: "Capitalize",
    },
    title: {
      fontSize: "2rem",
      fontWeight: 800,
      color: "#fff",
    },
    boxContent: {
      zIndex: 1,
      position: "relative",
      borderBottomLeftRadius: "12px",
      borderBottomRightRadius: "12px",
      overflow: "hidden",
      borderWidth: "0 1px 1px 1px",
      borderStyle: "solid",
      borderColor: "#CCD5DC",
    },
    rightMobileIcon: {
      color: "#000000",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    eventFormatBox: {
      zIndex: 1,
      position: "relative",
      borderRadius: "12px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      padding: "16px 16px 25px",
      "& .MuiAvatar-circular": {
        width: 64,
        height: 64,
        [theme.breakpoints.down("sm")]: {
          width: 40,
          height: 40,
        },
      },
      "&:hover h5": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        padding: 16,
        "& .MuiAvatar-circular": {
          marginRight: 16,
        },
        "& p": {
          marginTop: "0 !important",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        padding: 8,
        "& .MuiAvatar-circular": {
          marginRight: 16,
        },
        "& p": {
          marginTop: "0 !important",
        },
      },
    },
    boxTitle: {
      color: "#000",
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 1.5,
      wordBreak: "break-all",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
    organizers: {
      padding: 0,
      marginBottom: 5,
      marginTop: 5,
      minHeight: 52,
      "& > .InfoTitle": {
        textTransform: "Capitalize",
        textAlign: "left",
        fontWeight: "normal",
        color: "#9e9e9e",
        wordBreak: "break-all",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
      },
      "& > .InfoSubtitle": {
        textTransform: "Capitalize",
      },
    },
    eventFormateText: {
      textAlign: "center",
      marginBottom: 0,
      padding: 0,
      "& > p": {
        fontSize: 16,
        textTransform: "Capitalize",
        color: "#000000",
        minHeight: 38,
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // display: "-webkit-box",
        // "-webkit-line-clamp": 1,
        // "-webkit-box-orient": "vertical",
        [theme.breakpoints.down("md")]: {
          fontSize: 14,
          minHeight: "initial",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
          minHeight: "initial",
        },
      },
      "& > .InfoSubtitle": {
        textTransform: "Capitalize",
        wordBreak: "break-all",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
      },
      "& h5": {
        fontSize: 18,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    bottomChips: {
      //  position:'relative',
      [theme.breakpoints.down("md")]: {
        width: "100%",
        maxWidth: "100%",
      },
      maxWidth: 417,
      minHeight: 50,
      overflowY: "hidden",
      overflowX: "auto",
      cursor: "grab",
      whiteSpace: "nowrap !important",
      " -webkit-overflow-scrolling": "touch",
      "&::-webkit-scrollbar": {
        height: "1em",
        cursor: "grab",
      },

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "& > .MuiChip-root": {
        marginRight: 5,
        marginBottom: 10,
        color: "#6D7A84",
        borderColor: "#6D7A84",
      },

      "& > .MuiChip-sizeSmall > .MuiChip-labelSmall": {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 10,
        fontWeight: 600,
        display: "inline-block",
      },
    },
    filterChips: {
      flexDirection: "row",
      flexWrap: "wrap",
      "& > .MuiChip-root": {
        marginRight: 12,
        marginBottom: 6,
      },
    },

    filterbtns: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    filterDivider: {
      margin: "20px 0",
    },
    filterSearchBox: {
      width: "100%",
      border: "1px solid #CCD5DC",
      borderRadius: 5,
      padding: "8px 0 5px 10px",
      fontSize: 14,
      "& svg": {
        color: "#A6B3BE",
      },
    },
    dateWrap: {
      flexDirection: "column",
    },
    moreSection: {
      background: "#EEF3F7",
    },
    homeSection: {
      background: "#ffffff",
    },
    filterBox: {
      boxShadow: "none",
      border: "1px solid #CCD5DC",
      borderRadius: 20,
    },
    noResults: {
      borderRadius: 20,
      textAlign: "center",
      "& img": {
        maxWidth: "400px",
      },
      "& p": {
        maxWidth: "400px",
        margin: "auto",
      },
      "& h3": {
        marginBottom: 8,
      },
    },

    datePicker: {
      border: "1px solid #CCD5DC",
      borderRadius: 5,
      marginTop: 10,
      padding: "8px 0 5px 10px",

      "& .MuiInput-underline:before": {
        display: "none",
      },
      "& input": {
        fontWeight: 700,
        fontSize: 14,
        color: "#000",
      },
    },
    dateText: {
      fontWeight: 600,
    },
    timeText: {
      fontWeight: 600,
      color: "#6D7A84",
    },
    locText: {
      color: "#6D7A84",
      display: "flex",
      marginLeft: 10,
      // alignItems: 'center',
      height: 17,
      overflow: "hidden",
      flex: 1,
      "& svg": {
        fontSize: 18,
      },
    },
    priceBox: {
      display: "flex",
      marginTop: 10,
      "& div": {
        marginRight: 8,
      },
    },

    viewMoreBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 0,
      marginRight: 0,
      position: "relative",
      zIndex: 1,
      "& a": {
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        lineHeight: "26px",
        textTransform: "capitalize",
        color: "#6D7A84",
        fontWeight: "bold",
        "&:hover": {
          color: theme.palette.primary.main,
          textDecoration: "none",
        },
        "& svg": {
          fontSize: 15,
          marginLeft: 7,
          marginTop: 2,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 12,
          minWidth: 100,
          justifyContent: "end",
        },
      },
      "& p": {
        fontSize: 36,
      },
    },
    centerViewmore: {
      justifyContent: "center",
    },
    viewmoreTitle: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    navBox: {
      display: "flex",
      "& a, & p": {
        marginRight: 8,
        marginLeft: 8,
        color: "rgba(0, 0, 0, 0.5)",
        fontSize: 17,
        "&:first-of-type": {
          marginLeft: 0,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
      },
      "& a:hover": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
      "& p": {
        fontSize: 18,
        color: "#505B63",
      },
    },
    homeCardSearchBox: {
      borderBottom: "1px solid #CCD5DC",
      padding: "8px 0 5px 10px",
      fontSize: 14,
      "& svg": {
        color: "#A6B3BE",
        marginRight: 8,
      },
    },
    popularTagsSlide: {
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        display: "block",
        width: "100%",
        height: "100%",
        background: "rgba(0,0,0,0.6)",
        zIndex: 0,
        borderRadius: 12,
        opacity: 0.7,
        transition: "opacity 0.4s ease",
      },
      "&:hover": {
        "&:after": {
          opacity: 1,
        },
        "& .MuiCardActionArea-focusHighlight": {
          opacity: 0,
        },
      },
      "& > div": {
        minHeight: "auto",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    popupTagsCard: {
      position: "relative",
      height: "100%",
      "& a": {
        height: "100%",
      },
      "& span": {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        position: "absolute",
        left: 16,
        bottom: 22,
        marginBottom: 0,
        right: 16,
        zIndex: 1,
      },

      "&:hover": {
        // '&:after': {
        //   opacity: 1
        // },
        "& .MuiCardActionArea-focusHighlight": {
          opacity: 0,
        },
      },
    },
    activeFilter: {
      color: "#F96634 !important",
    },
    filtersCountnFilter: {
      display: "flex",
      alignItems: "center",
    },
    filtersChipTop: {
      display: "flex",
      marginTop: 4,
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      "& p": {
        fontSize: 14,
        lineHeight: "14px",
        fontWeight: "bold",
        marginRight: 16,
        paddingTop: 8,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 16,
        },
      },
      "& .MuiChip-root": {
        borderRadius: 4,
        border: 0,
        background: "#FEF3ED",
        padding: 8,
        height: "auto",
        marginBottom: 16,
      },
      "& .MuiAvatar-root": {
        width: "auto",
        height: "auto",
        backgroundColor: "transparent",
        fontSize: 12,
        lineHeight: "14px",
        textTransform: "capitalize",
        color: theme.palette.primary.mainOrange,
        fontWeight: "normal",
        marginLeft: 0,
        overflow: "initial",
      },
      "& .MuiChip-label": {
        display: "inline-block",
        fontSize: 12,
        fontWeight: 700,
        color: theme.palette.primary.mainOrange,
      },
      "& > div": {
        flex: 1,
      },
    },
    clearFilters: {
      fontSize: 14,
      lineHeight: "17px",
      textDecorationLine: "underline",
      textTransform: "capitalize",
      color: "#505B63",
      fontWeight: 700,
      paddingTop: 0,
      marginTop: "-8px",
      "&:hover": {
        textDecorationLine: "underline",
        backgroundColor: "transparent",
      },
    },
    collectionCard: {
      cursor: "pointer",
      border: "1px solid #CCD4DA",
      padding: 16,
      borderRadius: 12,
      transition: "0.5s ease",
      marginBottom: 20,
      marginLeft: 10,
      marginRight: 10,
      minHeight: 146,
      "& a:hover": {
        textDecoration: "none",
      },
      "&:hover": {
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
        "& div:last-of-type": {},

        "& p:first-of-type": {
          color: theme.palette.primary.main,
        },
      },
      "& a": {
        display: "flex",
        alignItems: "center",
      },
      [theme.breakpoints.down("sm")]: {
        padding: 8,
        borderRadius: 4,
        marginLeft: 8,
        marginRight: 8,
      },
    },
    collectionContent: {
      textAlign: "left",
      flex: 1,
      [theme.breakpoints.down("xs")]: {
        minHeight: "unset",
      },
      "& h4": {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "19px",
        color: "#000",
        wordBreak: "break-all",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textAlign: "left",
        textOverflow: "ellipsis",
      },
      "& p": {
        // margin: '8px 0 0',
        "&:first-of-type": {
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "19px",
          color: "#000",
          margin: "8px 0",
          wordBreak: "break-all",
          display: "-webkit-box",
          "-webkit-line-clamp": 1,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          textAlign: "left",
          textOverflow: "ellipsis",
          [theme.breakpoints.down("sm")]: {
            fontSize: 14,
          },
        },
        "&:last-of-type": {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "19px",
          color: "#505B63",
          wordBreak: "break-all",
          display: "-webkit-box",
          "-webkit-line-clamp": 1,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          [theme.breakpoints.down("sm")]: {
            height: 57,
          },
          [theme.breakpoints.down("xs")]: {
            height: "unset",
            margin: "8px 0 0",
          },
        },
      },
      "& span": {
        padding: "4px 8px",
        background: "#F1F5F8",
        border: "0.5px solid #FFFFFF",
        backdropFilter: "blur(20px)",
        borderRadius: 24,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "17px",
        color: "#000",
      },
    },
    collectionThumbnail: {
      height: 80,
      width: "125px",
      borderRadius: "8px",
      overflow: "hidden",
      position: "relative",
      marginRight: 16,
      "&:after": {
        width: "100%",
        bottom: 0,
        height: "100%",
        content: '""',
        display: "block",
        position: "absolute",
        background: "linear-gradient(to top, rgb(0 0 0 / 52%), rgba(0,0,0,0))",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    collectionCarousel: {
      [theme.breakpoints.down("lg")]: {
        marginTop: 70,
      },
      "& .slick-slide": {
        [theme.breakpoints.up("sm")]: {
          display: "inline-block",
          width: "50% !important",
        },
      },
    },
    moreCollectionCard: {
      cursor: "pointer",
      border: "1px solid #CCD4DA",
      padding: 16,
      borderRadius: 12,
      transition: "0.5s ease",
      marginBottom: 20,
      marginLeft: 10,
      marginRight: 10,
      "& a:hover": {
        textDecoration: "none",
      },
      "&:hover": {
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
        "& div:last-of-type": {},

        "& p:first-of-type": {
          color: theme.palette.primary.main,
        },
      },
    },
    moreCollectionContent: {
      paddingTop: 32,
      minHeight: 126,
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        minHeight: "unset",
      },
      "& h4": {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "22px",
        color: "#000",
        wordBreak: "break-all",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textAlign: "left",
        textOverflow: "ellipsis",
      },
      "& p": {
        "&:first-of-type": {
          fontWeight: 400,
          fontSize: 18,
          lineHeight: "22px",
          color: "#000",
          wordBreak: "break-all",
          display: "-webkit-box",
          "-webkit-line-clamp": 1,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          textAlign: "left",
          textOverflow: "ellipsis",
        },
        "&:last-of-type": {
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "19px",
          color: "#505B63",
          margin: "8px 0",
          [theme.breakpoints.down("sm")]: {
            height: 57,
          },
          [theme.breakpoints.down("xs")]: {
            height: "unset",
          },
        },
      },
      "& span": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "17px",
        color: "#6D7A84",
      },
    },
    moreCollectionThumbnail: {
      height: 170,
      width: "100%",
      borderRadius: "12px",
      overflow: "hidden",
      position: "relative",
      "&:after": {
        width: "100%",
        bottom: 0,
        height: "100%",
        content: '""',
        display: "block",
        position: "absolute",
        background: "linear-gradient(to top, rgb(0 0 0 / 52%), rgba(0,0,0,0))",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    moreAboutCollection: {
      background: "#FEF0EB",
      borderRadius: 12,
      padding: "48px 32px",
      margin: "100px 0",
      "& h2": {
        textTransform: "capitalize",
        marginBottom: 14,
      },
      "& p": {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "22px",
        color: "#000000",
      },
    },
  }));
};

export const modalStyles = () => {
  return makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalBox: {
      background: "#FFFFFF",
      borderRadius: 12,
      padding: 24,
      position: "relative",
      minWidth: 700,
      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
      },
    },
    likeModalBox: {
      "& img": {
        height: 300,
      },
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        "& img": {
          height: 200,
        },
      },
      [theme.breakpoints.down("xs")]: {
        "& img": {
          height: 150,
        },
      },
    },
    voteContents: {
      textAlign: "center",
      padding: "0 24px 24px",
      "& h2": {
        fontSize: 32,
        lineHeight: "38px",
      },
      "& p": {
        maxWidth: 380,
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
        margin: "16px auto 24px",
      },
    },
    voteContentBtn: {
      "& .MuiButton-containedPrimary": {
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
        borderRadius: "64px",
        padding: "16px 60px",
        boxShadow: "none",
        minWidth: "396px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%",
        },
      },
    },
  }));
};

export const navbarStyles = () => {
  return makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    logoImg: {
      width: 140,
      padding: "8px 0",
      [theme.breakpoints.down("sm")]: {
        width: 120,
      },
    },
    eventeerLogoImg: {
      width: 190,
      padding: "8px 0",
      [theme.breakpoints.down("sm")]: {
        width: 140,
      },
    },
    leftHeaderSt: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between",
        display: "none",
      },
      "& p": {
        width: "auto",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 20,
        marginLeft: "calc(20% - 90px)",
      },
    },
    stHeadergridt: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        width: "100%",
      },
    },
    submitNominationBtn: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    rightHeaderSt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        justifyContent: "end",
        width: "100%",
        paddingBottom: 10,
      },
      "& a": {
        marginLeft: 5,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    shadow: {
      boxShadow: "none !important",
    },
    search: {
      minWidth: 500,
      display: "flex",
      position: "relative",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        minWidth: 430,
      },
      "& > .MuiInputBase-root": {
        width: "100%",
        // paddingRight: 90,
        "& svg": {
          fontSize: 25,
          cursor: "pointer",
        },
      },
      "& form > div": {
        width: "100%",
        minWidth: 500,
        [theme.breakpoints.down("sm")]: {
          minWidth: 430,
        },
        [theme.breakpoints.down("xs")]: {
          // width: 'auto',
          minWidth: "unset",
        },
      },
      [theme.breakpoints.down("xs")]: {
        // marginLeft: theme.spacing(3),
        position: "static",
        minWidth: "auto",
        "& > *": {
          width: "100%",
          minWidth: "unset",
        },
      },
    },
    searchBtnR: {
      position: "absolute",
      right: 5,
      padding: "6px 20px",
      top: 3,

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    sectionDesktop: {
      // display: 'none',
      display: "flex",
      alignItems: "center",
      "& > button:last-child": {
        marginRight: 0,
      },
      "& > a:last-of-type": {
        marginLeft: 24,
        [theme.breakpoints.down("md")]: {
          padding: "7px 12px 3px",
          fontSize: "10px",
          marginLeft: 0,
        },
      },
      "& > a:nth-child(2)": {
        marginLeft: 4,
      },
      "& a:hover": {
        textDecoration: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
      "& a": {
        [theme.breakpoints.down("sm")]: {
          padding: "6px 10px !important",
          fontSize: "10px !important",
        },
        [theme.breakpoints.down("xs")]: {
          padding: "6px !important",
        },
      },
      "& .eventcreatebtn": {
        marginLeft: "16px !important",
        [theme.breakpoints.down("md")]: {
          padding: "8px 20px !important",
          fontSize: "13px !important",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "6px 10px !important",
          fontSize: "10px !important",
          marginLeft: "8px !important",
        },
      },
    },
    sectionMobile: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },

    searchIconMobile: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    searchBarMobile: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
        transition: "0.5s",
        position: "absolute",
        left: 0,
        zIndex: 99,
      },
    },
    searchBarToggle: {
      bottom: "-41px",
      display: "inline-flex",
    },
    fixednavbarMain: {
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    mobRightBtn: {
      top: "48px !important",
      zIndex: "9999 !important",
    },
    innerGutterHeader: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }));
};

export const bannerStyles = () => {
  return makeStyles((theme) => ({
    imageArea: {
      // '&:after': {
      //   content: '""',
      //   position: 'absolute',
      //   bottom: 0,
      //   display: 'block',
      //   width: '100%',
      //   height: '100%',
      //   background: 'linear-gradient(90deg, rgba(242,101,48,0.7800070711878502) 0%, rgba(239,67,115,0.7772059507396709) 100%)',
      // },
    },
    miniBannerText: {
      position: "relative",
      textAlign: "center",
      "& > .MuiTypography-root": {
        color: "#000000",
      },
      "& > h3": {
        marginBottom: 15,
      },
      "& h1": {
        fontSize: 24,
      },
    },
    whiteBreadcrumb: {
      color: "#FFFFFF",
      "& a, & p": {
        color: "#fff",
        fontSize: "14px",
        lineHeight: "17px",
      },
    },
    detailPageImageArea: {
      background: "url(" + edetailbg + ")",
      height: 380,
      // '&:after': {
      //   content: '""',
      //   position: 'absolute',
      //   bottom: 0,
      //   display: 'block',
      //   width: '100%',
      //   height: '100%',
      //   // background: 'linear-gradient(90deg, rgb(241 241 241) 0%, rgb(242 102 46 / 72%) 100%)',
      // },
    },
    detailPageBannerText: {
      position: "relative",
      textAlign: "Left",
      maxWidth: 650,
      paddingBottom: 20,
      "& > .MuiTypography-root": {
        color: "#000000",
        marginBottom: 15,
      },
      "& h1": {
        [theme.breakpoints.down("sm")]: {
          fontSize: 26,
        },
      },
    },
    detailPageBannerDate: {
      fontSize: 24,
    },
    detailPageBannerorganiser: {
      fontSize: 16,
      lineHeight: 1.6,
    },
    buttonSec: {
      "& > .MuiButton-root": {
        margin: "10px 10px 40px 0",
      },
    },
    // mapText: {
    //   display: 'flex',
    //   '& p': {
    //     marginLeft: 10,
    //     marginRight: 10
    //   },
    //   '& a': {
    //     [theme.breakpoints.down('md')]: {
    //       minWidth: 90,
    //       display: 'flex',
    //       alignItems: 'center',
    //     },
    //     [theme.breakpoints.down('xs')]: {
    //       minWidth: 75,
    //       textAlign: 'right'
    //     }
    //   }
    // },
    bannerEventEventStatus: {
      display: "flex",
      alignItems: "center",
    },
    sponsorshipApply: {
      marginLeft: 16,
      height: 24,
      background: "#fff",
      borderRadius: 49,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "4px 8px",
      "& > span": {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        color: "#000000",
        margin: 0,
      },
      "& button": {
        textDecorationLine: "underline",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        padding: 0,
        minWidth: "auto",
        textTransform: "capitalize",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    shareModal: {
      background: "#FFFFFF",
      borderRadius: 12,
      padding: 24,
      "& iframe": {
        maxWidth: "100%",
      },
    },
    shareModalTitle: {
      position: "relative",
      "& h5": {
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "22px",
      },
      "& button": {
        position: "absolute",
        right: "-20px",
        top: "-20px",
        "& svg": {
          color: "#505B63",
        },
      },
    },
    // bannerCardWrap: {
    //   position: 'absolute',
    //   bottom: 0,
    //   width: '100%',
    //   [theme.breakpoints.down('xs')]: {
    //     position: 'relative',
    //   },
    // },
  }));
};

export const footerStyles = () => {
  return makeStyles((theme) => ({
    anchor: {
      display: "inline-flex",
      alignItems: "center",
      padding: 8,
      background: "#505B63",
      borderRadius: 25,
      margin: " 0 3px",
    },
    icon: {
      color: "#fff",
    },

    // listNumber: {
    //   '& > li a':{
    //     color: '#3f4850',
    //   }
    // },
    connectVfairs: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& p": {
        color: "#000",
        letterSpacing: 0,
        fontSize: 14,
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "baseline",
      },
    },
    newsGridWrap: {
      //maxWidth: 1120,
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    newsLetterTagline: {
      fontSize: 32,
      color: "#474748",
      marginBottom: 8,
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: 24,
      },
    },
    newsLetterSubTagline: {
      textAlign: "left",
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        lineHeight: 1.5,
        marginBottom: 20,
      },
    },
    findUs: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      "& p": {
        color: "#000",
        letterSpacing: 0,
        fontSize: 14,
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "baseline",
      },
    },
    contTwo: {
      background: "#ffffff",
    },
    contThre: {
      background: "#f1f7fd",
      borderRadius: "12px",
      "& > div": {
        padding: "0 5%",
        "& ul": {
          "&:first-of-type": {
            marginBottom: 25,
            paddingBottom: 0,
            [theme.breakpoints.down("sm")]: {
              marginBottom: 0,
            },
          },
          "& li": {
            margin: 0,
            color: "#000",
            padding: 0,
            [theme.breakpoints.down("sm")]: {
              margin: 0,
              paddingTop: 24,
              justifyContent: "flex-start",
            },
            "& strong": {
              paddingRight: "5px",
              fontSize: "14px",
            },
            "& a": {
              color: "#3f4850",
              fontSize: "14px",
            },
          },
        },
      },
    },
    contFou: {
      background: "#E2E8ED",
      maxWidth: "1440px",
      borderRadius: "12px",
      //margin: '0 auto 50px',
      padding: "21px 0",
      "& > div": {
        padding: "0 5%",
      },
    },
    poweredBy: {
      maxWidth: "1440px",
      margin: "0 auto",
      padding: "21px 0",
      "& > div": {
        padding: "0 5%",
      },
    },
    poweredBySection: {
      textAlign: "center",
      fontWeight: "bold",
      "& img": {
        verticalAlign: "middle",
        marginLeft: 8,
      },
    },
    socialLinks: {
      marginTop: 15,
      "& img": {
        width: "28px",
        height: "auto",
      },
      "& a": {
        margin: "0 5px 10px",
        display: "inline-flex",
        alignItems: "center",
      },
    },
    mailList: {
      listStyle: "none",
      paddingLeft: 0,
      "& > li a": {
        marginLeft: 8,
        fontSize: 14,
      },
      "& > li:nth-child(1)": {
        "& a": {
          marginLeft: 5,
        },
      },
      "& > li:nth-child(2)": {
        "& a": {
          marginLeft: 5,
        },
      },
      "& > li:nth-child(3)": {
        "& a": {
          marginLeft: 5,
        },
      },
      "& > li:nth-child(4)": {
        "& a": {
          marginLeft: 5,
        },
      },
      "& > li:nth-child(5)": {
        "& a": {
          marginLeft: 5,
        },
      },
      "& > li span": {
        fontSize: 14,
      },
    },
    mobJustify: {
      [theme.breakpoints.down("xs")]: {
        alignItems: "baseline",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      },
    },
    newsletters: {
      background: "#e2e8ed",
      padding: "30px 0 30px",
      // marginTop: 60,
    },
    newslettersMain: {
      marginBottom: 60,
      [theme.breakpoints.down("sm")]: {
        marginTop: 40,
      },
      "& > div": {
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    FeatureCTAModal: {
      background: "#FFFFFF",
      position: "relative",
      borderRadius: 12,
      padding: "0 24px",
      top: 32,
      "& iframe": {
        maxWidth: "100%",
      },
      "& button": {
        position: "absolute",
        right: 3,
        top: 3,
        "& svg": {
          color: "#505B63",
        },
      },
    },
    FeatureCTAModalMain: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    newslettersInner: {
      background: "url(" + newsletterBG + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: "64px 0",
      borderRadius: "12px",
      [theme.breakpoints.down("sm")]: {
        padding: "48px 16px",
      },
    },
    newsletterContent: {
      maxWidth: 433,
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 16,
      "& h2": {
        fontSize: 32,
        lineHeight: "38px",
        textAlign: "center",
        color: "#FFFFFF",
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        textAlign: "center",
        color: "#FFFFFF",
      },
      "& button": {
        background: "#FFFFFF",
        borderRadius: "100px",
        padding: "12px 16px",
        fontSize: 16,
        lineHeight: "19px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#F96634",
        display: "inline-block",
      },
    },
    basicBadges: {
      paddingTop: 110,
      "& > div": {
        "& > div": {
          maxWidth: 1630,
          borderBottom: "1px solid #cccccc6b",
          paddingBottom: 29,
          margin: "0 auto",
          padding: "0 5%",
          [theme.breakpoints.down("md")]: {
            padding: "0 3%",
          },
        },
      },
    },
    logoInfo: {
      [theme.breakpoints.down("md")]: {
        marginBottom: 40,
      },
      "& p": {
        color: "#000",
        fontSize: "18px",
        maxWidth: "300px",
        marginBottom: 25,
        marginTop: 10,
      },
      "& a": {
        textTransform: "capitalize",
        maxWidth: 133,
        width: "100%",
        textAlign: "center",
      },
      "& a:hover": {
        color: "#fff",
        background: "#f96634",
      },
    },
    fmidFooter: {
      borderTop: "1px solid #cccccc6b",
      paddingTop: "20px",
      paddingBottom: "20px",
      marginTop: "2px",
      "& > div": {
        margin: "0 auto",
        padding: "0 5%",
        maxWidth: 1024,
      },
      "& p": {
        color: "#000",
        fontSize: "14px",
      },
    },
    fbadges: {
      padding: 0,
      margin: 0,
      display: "flex",
      listStyle: "none",
      flexWrap: "wrap",
      "& li": {
        paddingRight: 10,
        [theme.breakpoints.down("lg")]: {
          paddingRight: 20,
        },
        "& a, & img": {
          [theme.breakpoints.down("lg")]: {
            maxWidth: 85,
          },
          [theme.breakpoints.down("md")]: {
            maxWidth: 49,
          },
        },
      },
    },
    compliances: {
      display: "flex",
      listStyle: "none",
      padding: 0,
      justifyContent: "center",
      "& li": {
        margin: "0 5px",
        "& img": {
          width: 27,
        },
        [theme.breakpoints.down("sm")]: {
          "&:first-of-type": {
            marginLeft: 0,
          },
        },
      },
    },
    fLinkInner: {
      maxWidth: 1630,
      margin: "0 auto",
      padding: "0 5%",
      "& > div": {
        justifyContent: "center",
      },
    },
    fwrapBox: {
      maxWidth: 1440,
      margin: "0 auto",
      padding: "0 5%",
      backgroundColor: "#F1F5F8",
      padding: "18px 0px 1px",
      borderRadius: "12px",
      marginBottom: "20px",
      marginTop: "15px",
    },
    femails: {
      padding: "0 5%",
      [theme.breakpoints.down("md")]: {
        padding: "0 3%",
      },
      "& ul": {
        margin: 0,
        maxWidth: 1400,
        margin: "9px auto 0",
        display: "flex",
        justifyContent: "center",
        marginBottom: 25,
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
        "& li": {
          margin: "0 18px",
          [theme.breakpoints.down("md")]: {
            margin: "0 0px",
          },
        },
      },
    },
    secLocation: {
      color: "#3f4850",
      letterSpacing: 0,
      fontSize: 16,
      marginBottom: "26px !important",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left !important",
        marginBottom: "10px !important",
      },
    },
    listNumber: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: 1024,
      margin: "0 auto",
      "& > li > div + div": {
        flex: "unset",
      },
      "& > li": {
        justifyContent: "center",
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.between("sm", "md")]: {
          display: "block",
        },
        "& a": {
          color: "#3f4850",
          fontFamily: theme.typography.fontFamily,
          [theme.breakpoints.between("sm", "md")]: {
            display: "block",
            marginTop: 3,
          },
        },
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 0,
        paddingBottom: 0,
        display: "block",
      },
    },

    grow: {
      flexGrow: 1,
    },
    copyLink: {
      "& > a": {
        margin: " 0 12px",
        fontWeight: "bold",
        color: "inherit",
        [theme.breakpoints.down("xs")]: {
          margin: "3px 0",
          width: "100%",
          display: "block",
          textAlign: "left",
        },
      },
    },

    copyRight: {
      display: "contents",
      "& > p": {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column-reverse",
        alignItems: "baseline",
        width: "100%",
        "& > p": {
          color: "#000",
        },
      },
    },
    otherLogo: {
      listStyle: "none",
      display: "flex",
      paddingLeft: 0,
      alignItems: "center",
      "& > li + li": {
        marginLeft: 20,
      },
    },
    rightBadge: {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      paddingLeft: 0,
      "& > li + li": {
        marginLeft: 12,
      },
    },
    leaderBadge: {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      paddingLeft: 0,
      "& > li ": {
        marginRight: 12,
      },
    },
    scrolltioIconc: {
      position: "fixed",
      right: 30,
      bottom: 80,
      borderRadius: "50%",
      zIndex: 999,
      backgroundColor: [theme.palette.primary.main],
      "& button": {
        color: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: 40,
      },
    },
    footerColumns: {
      "& .MuiGrid-grid-md-2": {
        [theme.breakpoints.up("lg")]: {
          maxWidth: "16%",
          flexBasis: "16%",
        },
        [theme.breakpoints.down("lg")]: {
          maxWidth: "18%",
          flexBasis: "18%",
        },
        [theme.breakpoints.down("md")]: {
          maxWidth: "20%",
          flexBasis: "20%",
        },
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
          flexBasis: "100%",
        },
      },
    },
    footerColumnsHeading: {
      color: "#3f4850",
      letterSpacing: 0,
      marginBottom: "0.3em",
      marginBlockStart: "15px",
      marginInlineStart: 0,
      marginInlineEnd: 0,
      fontSize: 14,
    },
  }));
};

export const eventDetailStyles = () => {
  return makeStyles((theme) => ({
    eventTitle: {
      textTransform: "capitalize",
      marginBottom: 20,
      marginTop: 30,
    },
    infoTitle: {
      marginTop: 12,

      fontSize: 24,
    },
    main: {
      paddingRight: 0,
      marginRight: 0,
    },
    eventImg: {
      marginBottom: 0,
      textAlign: "center",
      borderRadius: "12px",
      overflow: "hidden",
      height: "100%",
      "& > img": {
        height: "100%",
        objectFit: "cover",
      },
    },
    eventSubTitle: {
      textTransform: "capitalize",
      marginBottom: 20,
      marginTop: 30,
    },
    eventDetail: {
      marginBottom: 20,
    },

    imageList: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "hidden",
      "& > li": {
        width: "220px !important",
        height: "auto !important",
        margin: "0 20px 20px 0",
        border: "1px solid #CCD5DC",
        borderRadius: 8,
        "& .lazyload-wrapper ": {
          height: "100%",
        },
      },
      "& > li  img": {
        width: "100%",
        height: "100px",
        objectFit: "contain",
      },
    },

    mainColWrap: {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    tagList: {
      marginTop: 20,
      "& > li": {
        display: "inline",
        paddingLeft: 0,
        paddingRight: 15,
      },
    },
    mainWrapContent: {
      lineHeight: "2",
      "& > img": {
        width: "100%",
      },
      "& p": {
        marginTop: 0,
      },
    },
    fixedTabsDetail: {
      [theme.breakpoints.up("md")]: {
        position: "fixed",
        top: 64,
        width: "100%",
        left: 0,
        padding: "0 80px",
        zIndex: 999,
        background: "#fff",
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        "& ul": {
          margin: 0,
          //maxWidth: '62%',
          "& li": {
            "& a": {
              borderTop: 0,
              borderBottom: "2px solid transparent",
              background: "#fff",
            },
          },
        },
      },
    },
    detailNavigation: {
      display: "flex",
      listStyle: "none",
      padding: 0,
      marginRight: 0,
      margin: "24px 0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        overflowX: "auto",
      },
      "& li": {
        flex: "1 1 auto",
        textAlign: "center",
        "& a": {
          background: "#EEF3F7",
          fontSize: 16,
          lineHeight: "19px",
          textTransform: "capitalize",
          color: "#6D7A84",
          display: "block",
          padding: "16px",

          borderTop: "2px solid transparent",
          "&:hover": {
            textDecoration: "none",
            color: "#000",
          },
          "&.active": {
            background: "#fff",
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
    sponsorshipApply: {
      marginLeft: 16,
      height: 24,
      background: "#fff",
      borderRadius: 49,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "4px 8px",
      "& > span": {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        color: "#000000",
        margin: "0 8px",
        [theme.breakpoints.down("xs")]: {
          fontSize: 10,
        },
      },
      "& button": {
        textDecorationLine: "underline",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        padding: 0,
        minWidth: "auto",
        textTransform: "capitalize",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    shareModal: {
      background: "#FFFFFF",
      borderRadius: 12,
      padding: 24,
      "& iframe": {
        maxWidth: "100%",
      },
    },
    shareModalTitle: {
      position: "relative",
      "& h5": {
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "22px",
      },
      "& button": {
        position: "absolute",
        right: "-20px",
        top: "-20px",
        "& svg": {
          color: "#505B63",
        },
      },
    },
    edetailBox: {
      background: "rgba(255, 255, 255, 0.95)",
      border: "1px solid #FFFFFF",
      boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(50px)",
      padding: 24,
      borderRadius: "24px",
      marginTop: "-331px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "-300px",
      },
    },
    edetailContent: {
      "& h1": {
        fontSize: 48,
        lineHeight: "58px",
        [theme.breakpoints.down("md")]: {
          fontSize: 38,
          lineHeight: "52px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 29,
          lineHeight: "40px",
        },
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
      },
    },
    mapText: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      "& svg": {
        paddingBottom: 5,
      },
      "& a": {
        fontSize: 12,
        textDecoration: "underline",
      },
    },
    regAddBtn: {
      display: "flex",
      gap: 16,
      alignItems: "center",
      padding: "16px 0",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        "& a, &>div": {
          width: "100%",
        },
      },
    },
    edetailregbtn: {
      "& a": {
        minWidth: 281,
        textAlign: "center",
        fontSize: 16,
        lineHeight: "24px",
        paddingTop: 11,
        "&:hover": {
          color: "#fff",
        },
      },
    },
    edetailaddtocalbtn: {
      a: {
        fontSize: 16,
        color: "#000",
        padding: "6px 24px",
      },
    },
    socialLinksM: {
      display: "flex",
      gap: 16,
      alignItems: "center",
      marginTop: 16,
      flexWrap: "wrap",
      "& img": {
        width: 48,
      },
    },
    socialSharecBox: {
      padding: "8px 0",
    },
    edetailcInfo: {
      border: 0,
    },
    edetailOrgImg: {
      marginTop: 32,
    },
    organizedBy: {
      background: "#FFFFFF",
      padding: 15,
      border: "1px solid #CCD4DA",
      borderRadius: "16px",
      height: "100%",
    },
    orgTitleby: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      marginBottom: 16,
      "& p, & span": {
        fontSize: 16,
        lineHeight: "19px",
        color: "#000",
      },
      "& .MuiTypography-h5": {
        minWidth: "110px",
      },
    },
    edetailagenda: {
      "& .MuiGrid-grid-md-2": {
        [theme.breakpoints.up("lg")]: {
          maxWidth: "13%",
          flexBasis: "13%",
        },
      },
      "& .MuiGrid-grid-md-10": {
        [theme.breakpoints.up("lg")]: {
          maxWidth: "87%",
          flexBasis: "87%",
        },
      },
    },
    organizedTitleIcon: {
      width: 100,
      height: "auto",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
    },
    organizeConTitle: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    edetailgallery: {
      background: "#F1F5F8",
      padding: "32px 180px",
      margin: "32px 0",
      borderRadius: 24,
      [theme.breakpoints.down("md")]: {
        padding: "32px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
      "& .slick-prev": {
        left: "24px",
      },
      "& .slick-next": {
        right: "24px",
      },
    },
    galleryItem: {
      width: "100%",
      "& img": {
        borderRadius: 12,
        width: "100%",
      },
    },
    edetailTitle: {
      fontSize: 24,
      lineHeight: "29px",
      textTransform: "capitalize",
      color: "#000000",
      marginBottom: 16,
    },
    edetailTitlembtt: {
      marginBottom: 32,
    },
    edetaileincl: {
      padding: "16px 0",
      "& .MuiGrid-grid-md-auto": {
        [theme.breakpoints.up("md")]: {
          flex: "1 1 auto",
        },
      },
    },
    edetailinclItem: {
      background: "#FFFFFF",
      border: "1px solid #CCD5DC",
      borderRadius: 8,
      padding: 16,
      textAlign: "center",
      "& p": {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "19px",
        textTransform: "capitalize",
        marginTop: 16,
        color: "#000000",
      },
    },
    edetailinclIcon: {
      width: 48,
      height: 48,
      borderRadius: "50%",
      margin: "auto",
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
      },
    },
    edsectionGap: {
      margin: "32px 0",
    },
    edetailmoredetail: {},
  }));
};

export const exhibitorCardStyles = () => {
  return makeStyles((theme) => ({
    root: {
      maxWidth: 260,
      minWidth: 260,
      // marginRight: 30,
      // marginBottom: 30,
    },
    main: {
      marginTop: -100,
      paddingTop: 100,
    },
    content: {
      padding: 20,
      height: 200,
      overflow: "hidden",
      textAlign: "center",
    },
    title: {
      marginBottom: 40,
      marginTop: 40,
    },
    exhibitMainCard: {
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "12px",
      margin: "15px 12px",
      maxWidth: "initial",
      minWidth: "auto",
      minHeight: 373,
      "& p": {
        color: "rgba(0,0,0,0.72)",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1.5,
      },
    },
    exhibitormModal: {
      maxWidth: 800,
      textAlign: "center",
      height: 600,
      overflowY: "auto",
      position: "relative",
    },
    closeModalCustom: {
      position: "absolute",
      background: "transparent",
      border: 0,
      right: 15,
      top: 15,
      /* font-size: 33px; */
      cursor: "pointer",
      zIndex: 9,
    },
    desriptionImageBoothDetail: {
      "& img": {
        maxWidth: "100%",
        height: "auto",
      },
    },
    exhiCardAction: {
      justifyContent: "center",
    },
    exhibitImageMain: {
      backgroundSize: "auto",
      backgroundColor: "rgb(255,255,255)",
      borderBottom: "1px solid #CCD5DC",
      // paddingBottom: '47.25%'
      padding: "30px",
      height: 125,

      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "scale-down",
        margin: "auto",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#fff",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
      padding: theme.spacing(2, 4, 3),
      borderRadius: "12px",
    },
    exhibitorWrap: {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 80,
      },
    },
  }));
};

export const speakerCardStyles = () => {
  return makeStyles((theme) => ({
    title: {
      marginTop: 40,
      marginBottom: 40,
    },
    main: {
      marginTop: -100,
      paddingTop: 100,
    },
    cardWrap: {
      width: "100%",
      marginBottom: 0,
      background: "#ffffff",
      borderRadius: 12,
      border: 0,
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
    },
    socialLinkWrap: {
      "& > a": {
        padding: " 5px 5px 0 0",
        fontSize: 18,
        verticalAlign: "sub",
      },
    },
    boxCardWrap: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    singleSpeakerWrap: {
      // background: '#FFFFFF',
      // border: '1px solid #CCD4DA',
      // borderRadius: 12,
      // padding: 16,
      // width: '100%',
      //minHeight: 142
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    infoTitle: {
      textTransform: "capitalize",
      marginBottom: 5,

      fontSize: 18,
    },

    infoSubTitle: {
      textTransform: "capitalize",
      marginBottom: 5,
    },
    agendaCardWrap: {
      width: "100%",
      marginBottom: 0,
      background: "#ffffff",
      borderRadius: 12,
      border: 0,
      boxShadow: "none",
    },
    agendaInfoTitle: {
      textTransform: "capitalize",
      // marginBottom: 8,
      fontSize: 14,
      wordBreak: "break-word",
      lineHeight: "17px",
    },
    speakersContainer: {
      marginBottom: 16,
    },
    shoemoreSpBtn: {
      textAlign: "center",
      "& button": {
        fontSize: 16,

        lineHeight: "26px",
        textTransform: "capitalize",
      },
    },
    speakerModal: {
      textAlign: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      borderRadius: 12,
      padding: 24,
      height: 600,
      maxWidth: 800,
      overflowY: "auto",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
      "& p": {
        fontSize: 16,
        lineHeight: "19px",
        textAlign: "center",
        color: "#505B63",
      },
    },
    speakerImage: {
      width: 96,
      height: 96,
      borderRadius: "50%",
      margin: "auto",
      overflow: "hidden",
      marginBottom: 16,
      "& .MuiAvatar-circular": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    speakerHead: {
      borderBottom: "1px solid #CCD4DA",
      paddingBottom: 16,
      marginBottom: 16,
      "& h6": {
        marginBottom: 8,
      },
    },
    modalMain: {
      zIndex: "9999 !important",
    },
    speakerSocial: {
      padding: 0,
      margin: 0,
      display: "flex",
      justifyContent: "center",
      listStyle: "none",
      marginTop: 18,
      "& li": {
        margin: "0 5px",
        "& a": {
          color: "#505B63",
          fontSize: 20,
        },
      },
    },
    speakerSeeMore: {
      "& button": {
        background: "#EEF3F7",
        borderRadius: 4,
        fontSize: 12,
        lineHeight: "14px",
        textTransform: "capitalize",
        color: "#505B63",
        padding: "5px 10px",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    speakerSeeMoreSpacer: {
      margin: "0 5px",
    },
    closeModalCustom: {
      position: "absolute",
      background: "transparent",
      border: 0,
      right: 15,
      top: 15,
      /* font-size: 33px; */
      cursor: "pointer",
      zIndex: 9,
    },
  }));
};

export const eventDetailCardStyles = () => {
  return makeStyles((theme) => ({
    eventIncludesM: {
      marginBottom: 10,
      marginTop: 10,
      textTransform: "capitalize",
      "& h5": {
        marginBottom: 12,
      },
      "& ul": {
        display: "flex",
        flexWrap: "wrap",
        "& > div": {
          display: "inline-flex",
          width: "auto",
          marginRight: 16,
          "& > div": {
            minWidth: "24px",
            "& svg": {
              width: "16px !important",
              height: "16px !important",
              marginTop: "-4px",
            },
          },
        },
      },
    },
    visit_CTA: {
      //borderTop: '1px solid #CCD4DA',
      borderBottom: "1px solid #CCD4DA",
      padding: "16px 0",
      //marginBottom: 16
    },
    organizedBy: {
      borderBottom: "1px solid #CCD4DA",
      padding: "16px 0 0 0",
      marginBottom: 16,
    },
    organizeConTitle: {
      display: "flex",
      alignItems: "center",
      margin: "16px 0",
      "& h6": {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",

        color: "#000000",
      },
    },
    organizeContents: {
      "& > p": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "17px",
        color: "#000000",
        paddingBottom: 16,
      },
    },
    organizedTitleIcon: {
      width: 48,
      height: 48,
      borderRadius: "50%",
      overflow: "hidden",
      marginRight: 16,
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    tagsSideMain: {
      marginTop: 16,
      "& .MuiTypography-h5": {
        fontSize: 18,
      },
    },
    socialLinks: {
      margin: "10px 15px 10px 0",
      "& button": {
        marginTop: 0,
        marginBottom: 0,
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: 10,
      },
    },
    card: {
      maxWidth: "100%",
      position: "relative",
      // borderRadius: '0 0 12px 12px',
      borderRadius: 12,
      borderBottom: 0,
      // boxShadow: 'none',
      padding: "0 20px 20px 20px",
      boxShadow: "0 8px 12px 0 rgb(0 0 0 / 12%)",
      overflow: "hidden",
      transition: "0.4s",
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        marginRight: 0,
      },
    },

    tagList: {
      marginTop: 0,
      display: "flex",
      flexWrap: "wrap",
      "& > li": {
        display: "inline-block",
        paddingLeft: 0,
        width: "auto",
        paddingRight: 10,
        marginBottom: 0,
      },
    },

    paraCards: {
      marginTop: 10,
      marginBottom: 15,
    },

    list: {
      minWidth: 35,
      paddingTop: 0,

      paddingBottom: 0,
      "& > div": {
        paddingBottom: 2,
        paddingTop: 2,
        "& first-of-type": {
          paddingTop: 0,
        },
      },
      "& > .MuiListItemText-root": {
        fontSize: 14,
      },
    },
    listIcon: {
      minWidth: 35,
      "& > .MuiSvgIcon-root": {
        width: 20,
        height: 20,
      },
    },
    buttonSignup: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 15,
      padding: "0 15px",
      "& > span > a": {
        marginRight: 15,
        marginTop: 4,
        fontSize: 18,
      },
    },
    signupTitle: {
      marginBottom: 20,
      marginTop: 20,
    },

    anchor: {
      display: "inline-flex",
      alignItems: "center",
      padding: 8,
      background: "#505B63",
      borderRadius: 25,
      margin: " 0 3px",
    },
    icon: {
      color: "#fff",
    },
    socialShareBox: {
      // marginTop: -170,
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
      },
    },

    bannerCardWrap: {
      // position: 'absolute',
      // bottom: 0,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        position: "relative",
      },
    },
    priceText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "16px",
      borderBottom: "1px solid #CCD4DA",
      "& > p": {
        fontSize: 24,
        lineHeight: "24px",
        marginBottom: 0,
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
      "& h4": {
        fontSize: 20,
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
    },
    edetailTimer: {
      padding: "16px 0",
      "& .timer-container": {
        gap: "16px",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        "& .timer": {
          border: "1px solid #F96634",
          borderRadius: "8px",
          padding: "6px 0",
          width: "20%",
          textAlign: "center",
        },
        "& .number": {
          fontSize: 21,
          margin: 0,
          [theme.breakpoints.down("xs")]: {
            fontSize: 14,
          },
          "& span": {
            fontWeight: 400,
          },
        },
      },
    },
    buyTicketBtn: {
      width: "100%",
      //marginTop: 15,
      "&:hover": {
        color: "#ffffff",
        opacity: "0.9",
      },
    },
    cardWrap: {
      maxWidth: "100%",
      position: "relative",
      borderRadius: "12px 12px 0 0",
      borderBottom: 0,
      boxShadow: "none",
      paddingTop: "20px",
      // border-top-left-radius: 12;

      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        marginRight: 0,
      },
      "& > button": {
        marginTop: 20,
        width: "100%",
      },
    },
    edetailcInfo: {
      boxShadow: "none",
      backgroundColor: "transparent",
      border: "1px solid #CCD4DA",
      borderRadius: "16px",
      padding: "16px",
    },
    edetailFormats: {
      padding: "16px 0",
      display: "flex",
      flexDirection: "column",
      gap: 24,
      "& > div": {
        "& p": {
          margin: 0,
          "&:first-of-type": {
            marginBottom: 16,
            fontSize: 18,
            lineHeight: "22px",
          },
        },
      },
    },
    edetailtags: {
      display: "flex",
      flexWrap: "wrap",
      gap: 8,
      paddingTop: 16,
      paddingBottom: 0,
      "& li": {
        padding: 0,
        width: "auto",
        "& .MuiChip-outlined": {
          color: "#6D7A84",
          borderColor: "#6D7A84",
          fontSize: 12,
          lineHeight: "15px",
        },
      },
    },
  }));
};

export const eventMapStyles = () => {
  return makeStyles((theme) => ({
    mapInfoBottom: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      margin: "16px 0 0",
      "& h4": {
        fontSize: 16,
        lineHeight: "19px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#000000",
        marginBottom: 0,
      },
      "& p, & a": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "17px",
        textTransform: "capitalize",
        color: "#000000",
        marginBottom: 0,
      },
    },
  }));
};

export const homeBannerStyles = () => {
  return makeStyles((theme) => ({
    homePageImageArea: {
      "& img": {
        background: "#fff",
        zIndex: -1,
      },
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        display: "block",
        width: "100%",
        height: "100%",
        // background: 'linear-gradient(90deg, rgb(241 241 241) 0%, rgb(242 102 46 / 72%) 100%)',
      },
    },

    homePageBannerText: {
      position: "relative",
      textAlign: "center",
      textTransform: "capitalize",
      "& > .MuiTypography-root": {
        color: "#fff",
        marginBottom: 15,
      },
      "& > h4": {
        marginBottom: 45,
      },
    },
    // mainText: {
    //   maxWidth: '60%',
    //   margin: 'auto',
    //   [theme.breakpoints.down('sm')]: {
    //     maxWidth: '100%',
    //   },
    // },
    searchBar: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 600,

      backgroundColor: "#ffffff",
      background: "#fff",
      // margin: 'auto',
      margin: "0",
      borderRadius: 30,
      border: "1px solid #bdbdbd",
      boxShadow: "none",
      [theme.breakpoints.down("sm")]: {
        width: 400,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        "& button": {
          padding: "9px 12px",
          fontSize: 10,
        },
      },

      "& a": {
        color: "rgba(255,255,255) !important",
      },
      "& input": {
        paddingRight: 10,
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
          fontSize: 13,
        },
      },
      "& div": {
        [theme.breakpoints.down("xs")]: {
          marginLeft: 0,
        },
      },
    },
    searchBox: {
      margin: "0 auto",
      "& .Mui-disabled": {
        color: "rgb(255 255 255)",
      },
      "& .MuiButton-containedPrimary": {
        background: "#F96634",
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      // backgroundColor:'#ffffff',
    },
    iconButton: {
      padding: 10,
      [theme.breakpoints.down("xs")]: {
        padding: "5px !important",
      },
    },
    tagChips: {
      marginTop: 30,
      justifyContent: "center",
      color: "#fff",
      textAlign: "center",
    },
    bannerImg: {
      "& img": {
        maxWidth: "100%",
      },
    },
    bannerMainHomeContent: {
      "& img": {
        width: 326,
        textAlign: "center",
        margin: "0 auto",
      },
      "& h1": {
        fontSize: 48,
        lineHeight: "58px",
        color: "#000000",
        textAlign: "left",

        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
          fontSize: 24,
          lineHeight: "29px",
        },
      },
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        marginBottom: 48,
        paddingTop: 32,
      },
    },
    bannerHomeContent: {
      "& img": {
        width: 326,
        textAlign: "center",
        margin: "0 auto",
      },
      "& h1": {
        fontSize: 48,
        lineHeight: "58px",
        color: "#000000",
        textAlign: "center",

        [theme.breakpoints.down("sm")]: {
          fontSize: 24,
          lineHeight: "29px",
        },
      },
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        marginBottom: 48,
        paddingTop: 32,
      },
    },
    searchBar: {
      width: 600,
      border: "1px solid #bdbdbd",
      marginLeft: 0,
      display: "flex",
      padding: "2px 4px",
      background: "#fff",
      boxShadow: "none",
      alignItems: "center",
      borderRadius: "30px",
      backgroundColor: "#ffffff",
      margin: "auto",
      "& input": {
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
        },
        "&::placeholder": {
          textTransform: "none",
        },
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        boxShadow: "none",
      },
      [theme.breakpoints.down("sm")]: {
        width: 400,
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    eventtitle: {
      margin: "32px 0",
    },
    sidedContainer: {
      marginLeft: "calc((100% - min(100%, 87.5rem))/2)",
      maxWidth: "none !important",
    },
    BannerEventeerImg: {
      position: "absolute",
      right: 0,
      bottom: 0,
      [theme.breakpoints.down("md")]: {
        position: "static",
        textAlign: "right",
      },
      "& img": {
        userSelect: "none",
        maxWidth: "88%",
        [theme.breakpoints.down("1340")]: {
          maxWidth: "80%",
        },
        float: "right",
      },
    },
    bannerEventeevs: {
      overflow: "hidden",
      position: "relative",
      minHeight: 550,
      [theme.breakpoints.down("sm")]: {
        minHeight: 450,
      },
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    bannerEventeerNom: {
      backgroundColor: "#24160C",
      padding: "60px 0 120px",
      background: "url(" + headerbgeventeer2025 + ")",
      backgroundPosition: "right",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    eventeerVideo: {
      padding: "60px 0 20px",
      "& iframe": {
        maxWidth: 800,
        width: "100%",
        height: 380,
      },
      [theme.breakpoints.down("xs")]: {
        "& iframe": {
          maxWidth: "400px",
          height: "250px",
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
    bannerHomeContentEventeer: {
      // width: '70%',
      color: "#ffffff",
      paddingTop: "40px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      textAlign: "left",
      margin: "0 auto",
      "& img": {
        width: 374,
      },
      "& h1": {
        fontSize: 48,
        lineHeight: "58px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 24,
          lineHeight: "29px",
        },
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 32,
      },
      "& p": {
        fontSize: 24,
        lineHeight: "24px",
        marginBottom: "30px",
      },
    },
    bannerNominee2024: {
      padding: "87px 0",
      background: "url(" + nomineeTitleImg2024 + ") no-repeat center / cover",
      "& h1": {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: "46px",
        textAlign: "center",
        textTransform: "capitalize",

        color: "#FFF",
      },
    },
    bannerNominee: {
      padding: "87px 0",
      background: "url(" + nomineeTitleImg + ") no-repeat center / cover",
      "& h1": {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: "46px",
        textAlign: "center",
        textTransform: "capitalize",
      },
    },
    nomineeDBanner2024: {
      background: "url(" + nomineeBanner2024 + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: 360,
    },
    nomineeDBanner: {
      background: "url(" + nomineeBanner + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: 360,
    },
    winnersCTA: {
      color: "#F96634",
      fontSize: 16,
      fontWeight: "bold",
      // marginLeft: 25,
      "& svg": {
        verticalAlign: "middle",
      },
    },
    // nominationCounter: {
    //   background: "url(" + nomineeHeaderBG + ") no-repeat center / cover",
    //   padding: '50px 0',
    //   borderRadius: 50,
    //   overflow: 'hidden',
    //   backgroundSize: 'cover',
    //   [theme.breakpoints.down('md')]: {
    //     backgroundSize: 'cover'
    //   }
    // },
    TimerSectionVoting: {
      background: "#F96634",
      "& a": {
        color: "#F96634",
        borderRadius: 96,
        margin: "0 40px",
        padding: "16px 30px",
        background: "white",
      },
      "& a:hover": {
        boxShadow: "0px 10px 10px rgb(0 0 0 / 15%)",
        textDecoration: "none",
      },
      "& > div": {
        "& > div": {
          maxWidth: 1630,
          borderBottom: "1px solid #cccccc6b",
          //paddingBottom: 29,
          margin: "0 auto",
          padding: "20px 10%",
          // [theme.breakpoints.down('md')]: {
          //   padding: '0 3%',
          // },
        },
      },
      "& h6": {
        fontWeight: 400,
        fontSize: 36,
        marginTop: 10,
        color: "#fff",
        [theme.breakpoints.down("md")]: {
          fontSize: 20,
        },
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "19px",
        marginTop: 5,
        marginBottom: 10,
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
    },
    TimerSection: {
      background: "#24160C",
      paddingBottom: "40px",
      "& > div": {
        "& > div": {
          //maxWidth: 1630,
          // borderBottom: '1px solid #cccccc6b',
          //paddingBottom: 29,
          //margin: '0 auto',
          padding: "20px 0",
          // [theme.breakpoints.down('md')]: {
          //   padding: '0 3%',
          // },
        },
      },
      "& h6": {
        fontWeight: 400,
        fontSize: 36,
        marginTop: 10,
        color: "#fff",
        [theme.breakpoints.down("md")]: {
          fontSize: 20,
        },
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "19px",
        marginTop: 5,
        marginBottom: 10,
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
          textAlign: "center",
        },
      },
    },
    // nominationCounterContainer: {
    //   maxWidth: 480,
    //   margin: 'auto',
    //   textAlign: 'center',
    //   '& p': {
    //     fontWeight: 400,
    //     fontSize: 36,
    //     lineHeight: '43px',
    //     color: '#FFFFFF',
    //     marginBottom: 40,
    //     [theme.breakpoints.down('sm')]: {
    //       fontSize: 24,
    //       lineHeight: '28px',
    //     },
    //   }
    // },
    eventeerCBox: {
      borderRadius: 8,
      width: "115.67px",
      height: 101,
      background: "rgba(255, 255, 255, 0.25)",
      padding: 16,
      border: "3px solid #FFFFFF",
      boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(20px)",
      [theme.breakpoints.down("sm")]: {
        margin: "auto",
      },
      "& span": {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "19px",
        color: "#FFFFFF",
        textAlign: "center",
        display: "block",
      },
      "& .number": {
        fontSize: 32,
        lineHeight: "38px",

        textAlign: "center",
        marginBottom: 12,
      },
    },
    bannerEvneerBtns: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      "& a:hover": { color: "#fff" },
      "& a": {
        display: "block",

        [theme.breakpoints.down("md")]: {
          marginTop: 16,
        },
      },
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
  }));
};

export const winnerBannerStyles = () => {
  return makeStyles((theme) => ({
    banner: {
      background: "url(" + winnerBanner + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: "81px 0",
      [theme.breakpoints.down("md")]: {
        padding: "32px 0",
      },
    },
    banner2024: {
      background: "url(" + winnerBanner2024 + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      padding: "81px 0",
      [theme.breakpoints.down("md")]: {
        padding: "32px 0",
      },
    },
    bannerContent: {
      textAlign: "center",
      "& h1": {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: "46px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        margin: "32px 0",
      },
      "& a": {
        color: "#F96634",
        fontSize: 16,
        fontWeight: "bold",
        marginLeft: 25,
        marginTop: "25px",
        display: "block",
        "& svg": {
          verticalAlign: "middle",
        },
      },
    },
  }));
};

export const eventeerStyles = () => {
  return makeStyles((theme) => ({
    honorBG: {
      // background: "url(" + honorbg + ")",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // borderRadius: '32px',
      padding: "64px 120px",
      borderRadius: "32px",
      border: "1px solid #F1614B",
      background: "#24160C",
      boxShadow:
        "0px 20px 30px 0px rgba(249, 102, 52, 0.20), 0px 10px 20px 0px rgba(255, 184, 0, 0.10)",
      [theme.breakpoints.down("md")]: {
        padding: "40px 60px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "30px",
        textAlign: "center",
      },
    },
    honor: {
      // margin: '50px 0',
      backgroundColor: "#24160C",
      background: "url(" + honornewbg + ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      // borderRadius: '32px',
      padding: "64px 0",
    },
    honorText: {
      "& h2": {
        color: "#FFFFFF",
      },
      "& p": {
        //fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#FFFFFF",
        marginTop: "16px",
      },
      "& ul": {
        padding: 0,
        "& li": {
          paddingLeft: 0,
          fontWeight: 400,

          lineHeight: "22px",
          // textTransform: 'capitalize',
          color: "#FFFFFF",
          "& span": {
            fontSize: "18px",
          },
          "& .MuiListItemIcon-root": {
            color: "#FFFFFF",
            minWidth: 30,
            "& svg": {
              width: 18,
              height: 18,
            },
          },
        },
      },
    },
    eventCategories: {
      backgroundColor: "#F1F5F8",
      padding: "60px 0",
      "& >div> h2": {
        color: "#000000",
        marginBottom: 40,
      },
    },
    categoryBoxCardMain: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    categoryBox: {
      position: "relative",
      background: "#FFFFFF",
      border: "1px solid #C2D0DC",
      borderRadius: "16px",
      padding: 12,
      marginLeft: 10,
      marginRight: 10,
      overflow: "hidden",
      "&:hover": {
        "& > div:nth-last-child(2)": {
          opacity: "0",
        },
        "& > div:last-of-type": {
          opacity: 1,
          height: " calc(100% - 40px)",
        },
      },
    },
    categoryImage: {
      borderRadius: "16px",
      overflow: "hidden",
      "& img": {
        width: "100%",
        borderRadius: "inherit",
        height: "370px",
      },
    },
    categorybtn: {
      position: "absolute",
      left: 20,
      bottom: 20,
      width: "calc(100% - 40px)",
      transition: "0.5s ease",
      "& a": {
        background: "#FFFFFF",
        borderRadius: "8px",
        padding: "24px 8px",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",
        textAlign: "center",
        // textTransform: 'capitalize',
        color: "#000000",
        display: "block",
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    categorymore: {
      position: "absolute",
      left: 20,
      bottom: 20,
      width: "calc(100% - 40px)",
      background: "rgba(239, 72, 118, 0.9)",
      borderRadius: "8px",
      textAlign: "center",
      padding: "24px 8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      transition: "0.5s ease",
      height: 0,
      opacity: 0,
      "& h2": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "19px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#FFFFFF",

        marginBottom: 8,
      },
      "& p": {
        fontSize: "16px",
        lineHeight: "19px",
        textAlign: "center",
        //textTransform: 'capitalize',
        color: "#FFFFFF",
      },
      "& a": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "26px",
        textAlign: "center",
        textTransform: "capitalize",
        color: "#FFFFFF",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    loadMore: {
      textAlign: "center",
      marginTop: 24,
    },
    winnerget: {
      background: "url(" + winnerbg2024 + ") no-repeat center / cover",
      margin: 0,
      padding: "48px 0",
    },
    moreEvents: {
      margin: "0",
    },
    moreEventsBtns: {
      margin: "20px 0",
      "& button": {
        border: "1px solid #fff",
        color: "#fff",
        margin: "10px",
      },
      "& a": {
        border: "1px solid #fff",
        color: "#fff",
        margin: "10px",
      },
      "& a:hover": {
        background: "#fff",
        color: "#F96634",
      },
      "& button:hover": {
        background: "#fff",
        color: "#F96634",
      },
    },
    moreEventsHead: {
      textAlign: "center",
      "& h2": {
        color: "#FFFFFF",
        marginBottom: 16,
      },
      "& p": {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "22px",
        color: "#FFFFFF",
      },
    },
    EventeerWrap: {
      maxWidth: "880px",
      margin: "auto",
      padding: "64px 0",
      borderRadius: 32,
    },
    winnerHead: {
      textAlign: "center",
      marginBottom: 40,
      "& h2": {
        color: "#000",
        marginBottom: 16,
      },
      "& p": {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
        marginBottom: 20,
      },
    },
    winnerBox: {
      borderRadius: 16,
      padding: 24,
      position: "relative",
      height: 540,
      "& h2": {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "38px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        marginBottom: 16,
      },
      "& ul": {
        padding: 0,
        "& li": {
          paddingLeft: 0,
          fontWeight: 400,

          lineHeight: "22px",
          // textTransform: 'capitalize',
          color: "#FFFFFF",
          "& span": {
            fontSize: 18,
          },
          "& .MuiListItemIcon-root": {
            color: "#FFFFFF",
            minWidth: 30,
            "& svg": {
              width: 18,
              height: 18,
            },
          },
        },
      },
    },
    winnerImg: {
      maxWidth: 185,
      marginLeft: "auto",
      position: "absolute",
      right: 0,
      bottom: 0,
      "& img": {
        maxWidth: "100%",
      },
    },
    eventeerTitle: {
      fontWeight: 700,
      fontSize: 48,
      lineHeight: "58px",
      textTransform: "capitalize",

      [theme.breakpoints.down("sm")]: {
        fontSize: 28,
        lineHeight: "1.5",
      },
    },
    moreTitle: {
      fontWeight: 700,
      fontSize: 48,
      lineHeight: "58px",

      [theme.breakpoints.down("sm")]: {
        fontSize: 28,
        lineHeight: "1.5",
      },
    },
    qna: {
      "& h2": {
        marginBottom: 40,
      },
    },
    accordionMain: {
      boxShadow: "none",
      background: "#F1F5F8",
      border: "1px solid #F1F5F8",
      borderRadius: "12px !important",
      marginBottom: "24px !important",
      padding: "38px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: "24px 18px",
      },
      "&:before": {
        display: "none",
      },
      "& .MuiAccordionSummary-root": {
        padding: "0",
        minHeight: "auto",
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
      },
      "& .MuiAccordionDetails-root": {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
      "& .MuiIconButton-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "&.Mui-expanded": {
        background: "#FEF0EB",
        border: "1px solid #F96634",
        "& .MuiAccordionSummary-root": {
          "& p": {
            fontWeight: 700,
          },
        },
      },
      "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
        transform: "rotate(135deg)",
      },
    },
    qnaTitle: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "20px",
      color: "#000000",
      transition: "0.3s ease",
    },
    qnaText: {
      borderTop: "1px solid #C2D0DC",
      paddingTop: 10,
      "& p": {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
        color: "#000000",
      },
    },
    nomineeCategories: {
      background: "#FAFAFA",
      padding: "28px 16px",
      border: "1px solid #C2D0DC",
      borderRadius: "12px",
      [theme.breakpoints.down("md")]: {
        //display: 'none'
      },
      "& h2": {
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "29px",
        color: "#000000",
      },
      "& ul": {
        padding: 0,

        "& li": {
          padding: 0,
          marginTop: 28,
          "& a": {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
            color: "#000000",
            display: "inline-block",
            position: "relative",
            "&::after": {
              content: '""',
              width: 8,
              height: 8,
              background: "#F96634",
              display: "inline-block",
              borderRadius: "50%",
              marginLeft: 12,
              opacity: 0,
            },
            "&.active": {
              color: "#F96634",
              "&::after": {
                opacity: 1,
              },
            },
          },
        },
      },
    },
    nomineeMai: {
      "& > h2": {
        fontWeight: 700,
        fontSize: "36px",
        lineHeight: "43px",

        color: "#000000",
        borderBottom: "1px solid #C2D0DC",
        paddingBottom: 18,
        marginBottom: 40,
      },
    },
    nomineeCard: {
      background: "#FFFFFF",
      border: "1px solid #C2D0DC",
      borderRadius: "16px",
      padding: 16,
      gap: 16,
      display: "flex",
      flexDirection: "column",
    },
    nomineeCardText: {
      gap: 16,
      display: "flex",
      flexDirection: "column",
      "& h2": {
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",

        color: "#000000",
      },
      "& > p": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        minHeight: 30,
        color: "#3F4850",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    NomineeHigh: {
      gap: 16,
      display: "flex",
      alignItems: "center",
      "& span": {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: "#606970",
      },
      "& p": {
        margin: 0,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: "#606970",
      },
      "& a": {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: "#606970",
        textDecoration: "underline",
        display: "flex",
        alignItems: "center",
        "& svg": {
          marginLeft: 10,
          width: 16,
          height: 16,
        },
      },
    },
    nomineeCardBtns: {
      display: "flex",
      alignItems: "center",
      borderTop: "1px solid #C2D0DC",
      paddingTop: 24,
      flexWrap: "wrap",
      gap: 5,
      "& button": {
        flex: "0 0 auto",
        width: "100%",
      },
      "& a": {
        flex: "0 0 auto",
        width: "100%",
      },
      "& .MuiButton-containedPrimary": {
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
        // marginRight: 16
      },
    },
    nomineeSlide: {
      width: "100%",
      height: 200,
      borderRadius: 12,
      overflow: "hidden",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: "200px",
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      "&:after": {
        width: "100%",
        bottom: 0,
        height: "100%",
        content: '""',
        display: "block",
        opacity: 0.9,
        zIndex: 0,
        position: "absolute",
        background: "rgba(0,0,0,0.2)",
        transition: "opacity 0.4s ease",
        borderRadius: 12,
      },
    },
    Winner: {
      position: "absolute",
      right: 10,
      zIndex: 99,
      width: "80px !important",
      height: "80px !important",
    },
    nominee: {
      margin: "40px 0 60px",
      "& .MuiGrid-grid-md-3": {
        [theme.breakpoints.up("md")]: {
          maxWidth: "27.62%",
          flexBasis: "27.62%",
        },
      },
      "& .MuiGrid-grid-md-9": {
        [theme.breakpoints.up("md")]: {
          maxWidth: "72.38%",
          flexBasis: "72.38%",
        },
      },
    },
    honorAwardImage: {
      [theme.breakpoints.down("lg")]: {
        display: "block",
        textAlign: "center",
        width: "100%",
        marginBottom: "30px",
      },
      "& img": {
        maxWidth: "100%",
        borderRadius: 16,
      },
    },
    nomineeRow: {
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    modalVoteStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    voteModal: {
      background: "#FFFFFF",
      borderRadius: "12px",
      position: "relative",
      minWidth: 656,
      maxWidth: 656,
      [theme.breakpoints.down("1370")]: {
        overflowY: "scroll",
        height: 580,
        marginTop: 62,
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        padding: 24,
      },
      "& button": {
        flex: "0 0 auto",
        width: "100%",
        padding: "14px 20px",
        lineHeight: "23px",
        borderRadius: "64px",
        marginTop: 16,
      },
      "& .MuiButton-containedPrimary": {
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
      },
    },
    voteModalClose: {
      top: 3,
      right: 3,
      position: "absolute",
      width: "10px !important",
      "&:hover": {
        borderRadius: 0,
      },
    },
    voteHeader: {
      textAlign: "center",
    },
    voteThanks: {
      width: "100%",
    },
    voteAuth: {
      width: "100%",
      marginTop: 20,
    },
    voteBox: {
      padding: "0 24px 24px",
      "& h2": {
        fontSize: 32,
        lineHeight: "38px",
        marginBottom: 16,
        marginTop: 16,
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
        marginBottom: 0,
        width: 350,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        margin: "0 auto",
      },
      "& form": {
        marginTop: 20,
        textAlign: "left",
      },
    },
    voteInput: {
      height: 56,
      padding: "8px 8px 8px 16px",
      background: "#FFFFFF",
      border: "1px solid #CCD4DA",
      borderRadius: 8,
      fontSize: 14,
      lineHeight: "24px",
      color: "#000",
      display: "block",
      width: "100%",
      marginBottom: 16,
    },
    voteForm: {
      "& .error": {
        color: "#f2545b",
        fontSize: 14,
      },
      "& .success": {
        color: "green",
        fontSize: 14,
      },
    },
    // voteCaptcha: {
    //   marginBottom: 13,
    // },
    nomnineForm: {
      padding: "20px 0 100px",
      backgroundColor: "#F1F5F8",
      [theme.breakpoints.down("sm")]: {
        padding: "60px 0 0",
      },
      "& h2": {
        fontSize: 36,
        lineHeight: "43px",
        marginBottom: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 26,
          lineHeight: "33px",
        },
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#000",
        marginBottom: 40,
      },
    },
    nomnineFormWrap: {
      maxWidth: 800,
      margin: "auto",
    },
    nomnineFormBox: {
      background: "rgba(255, 255, 255, 0.9)",
      border: "1px solid #E2E8ED",
      boxShadow: "0px 30px 40px rgba(160, 134, 125, 0.25)",
      backdropFilter: "blur(10px)",
      borderRadius: 12,
      //minHeight: 721,
      maxWidth: 800,
      marginLeft: "auto",
      marginRight: "auto",
      padding: "32px 22px",
    },
    // nomineeFormTitle:{
    //
    //   fontSize: 24,
    //   lineHeight: '29px',
    //   marginBottom: 19,
    //   color: '#000',
    //   textTransform: 'capitalize'
    // }
  }));
};

export const lookingUpStyles = () => {
  return makeStyles((theme) => ({
    lookingUp: {
      background: "#F1F5F8",
      padding: "32px 0",
    },
    themeHeader: {
      // textAlign: 'center',
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
      "& h2": {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "38px",
        textTransform: "capitalize",
        color: "#000000",

        marginBottom: 16,

        [theme.breakpoints.down("sm")]: {
          fontSize: 18,
          lineHeight: "22px",
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "22px",
        color: "#505B63",
        [theme.breakpoints.down("sm")]: {
          fontSize: 14,
          lineHeight: "17px",
        },
      },
    },
    themeHeaderWhite: {
      "& h2": {
        color: "#fff",
      },
      "& p": {
        color: "#fff",
      },
    },
    popularTagSingle: {
      maxWidth: 256,
      position: "relative",
      boxShadow: "none",
      height: 171,
      overflow: "hidden",
      borderRadius: "12px",
      transition: "0.4s",
      marginBottom: 0,
      marginRight: 8,
      marginLeft: 8,
      width: "256 !important",
      [theme.breakpoints.down("md")]: {
        maxWidth: 256,
      },
      [theme.breakpoints.down("sm")]: {
        width: "222px !important",
        maxWidth: "unset",
        marginRight: 5,
        height: 140,
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        margin: "auto",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        zIndex: 0,
        display: "block",
        width: "100%",
        bottom: -1,
        height: "100%",
        borderRadius: "1rem",
      },
    },
    eventTypeLinks: {
      display: "flex",
      listStyle: "none",
      justifyContent: "center",
      padding: 8,
      margin: "0 0 10px",
      "& li": {
        margin: 16,
        zIndex: 999,
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
        "& a": {
          padding: 16,
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "19px",
          textAlign: "center",
          textTransform: "capitalize",
          color: "rgba(0,0,0,0.6)",
          borderBottom: "1px solid transparent",
          minWidth: "120px",
          display: "inline-block",
          transition: "border 0.5s ease",
          textDecoration: "none",
          "&:hover": {
            borderColor: "#F96634",
            color: "rgba(0,0,0,1)",
          },
          "&.active": {
            borderColor: "#F96634",
            color: "rgba(0,0,0,1)",
          },
          [theme.breakpoints.down("sm")]: {
            minWidth: 160,
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        overflowX: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    homesectionGap: {
      padding: "80px 0",
      [theme.breakpoints.down("sm")]: {
        padding: "32px 0",
      },
    },
    sidedContainer: {
      marginLeft: "calc((100% - min(100%, 87.5rem))/2)",
      maxWidth: "none !important",
    },
    eventFormat: {
      "& h2": {
        color: "#fff",
      },
    },
    featurePointsBox: {
      background: "#FFF8E6",
      padding: "32px 0",
      [theme.breakpoints.down("md")]: {
        padding: "32px 0 64px",
      },
    },
    featurePoint: {
      display: "flex",
      alignItems: "center",
      "& img": {
        width: 50,
      },
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        marginTop: 32,
        "& p": {
          marginLeft: 0,
          marginTop: 16,
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#000000",
        marginLeft: 16,
        fontWeight: 700,
      },
    },
    hybridMain: {
      [theme.breakpoints.down("sm")]: {
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
      },
    },
    hybridbg: {
      background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
      borderRadius: "32px 0px 0px 32px",
      padding: "48px 0px 48px 32px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        padding: "16px 0",
        background: "none",
      },
    },
    hybridmore: {
      position: "absolute",
      bottom: 48,
      right: 36,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        position: "static",
        justifyContent: "center",
        marginBottom: 16,
      },
      "& a": {
        color: "#fff",
        "&:hover": {
          color: "#fff",
        },
      },
    },
    themeSearchColor: {
      "& > div": {
        borderColor: "#fff",
        width: 500,
      },
      "& svg": {
        color: "#fff",
      },
      "& input": {
        color: "#fff",
        fontSize: 16,
        lineHeight: "26px",
        textTransform: "capitalize",
        "&::placeholder": {
          color: "#fff",
        },
      },
    },
    collectionSectionGap: {
      padding: "160px 0",
      [theme.breakpoints.down("sm")]: {
        padding: "32px 0",
        textAlign: "center",
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#505B63",
        margin: "16px 0 24px",
      },
      "& h2": {
        marginBottom: 0,
      },
      "& a": {
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "26px",
        textTransform: "capitalize",
        color: "#F96634",
        display: "flex",
        alignItems: "center",
        "& svg": {
          marginLeft: 9,
          [theme.breakpoints.down("sm")]: {
            verticalAlign: "bottom",
          },
        },
      },
    },
    featureCircleObj: {
      position: "absolute",
      right: "-185px",
      top: "-20px",
      zIndex: -1,
    },
    upcomingObj: {
      position: "absolute",
      left: 0,
      top: "28%",
      [theme.breakpoints.down("sm")]: {
        left: "unset",
        right: 0,
      },
    },
    pFeatureEvent: {
      textAlign: "center",
      marginTop: 24,
    },
  }));
};

export const collectionBannerStyles = () => {
  return makeStyles((theme) => ({
    collectionBanner: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      boxShadow: "inset 0 0 0 1000px rgb(0 0 0 / 50%)",
      height: 280,
      marginBottom: 40,
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    collectionBannerContent: {
      maxWidth: 920,
      textAlign: "center",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // background: '#FFFFFF',
      borderRadius: 12,
      padding: 32,
      position: "relative",
      // bottom: -180,
      // boxShadow: '0px 0px 20px rgb(0 0 0 / 20%)',
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        padding: "20px 0",
      },
      "& h1": {
        fontWeight: 700,
        fontSize: 36,
        lineHeight: "43px",
        textTransform: "capitalize",
        color: "#fff",
        wordBreak: "break-all",
        [theme.breakpoints.down("sm")]: {
          fontSize: 32,
          lineHeight: "1.3",
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "19px",
        color: "#fff",
        margin: "16px 0",
      },
    },
    allCollectionBannerContent: {
      maxWidth: 920,
      textAlign: "center",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: 12,
      padding: 32,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        padding: "20px 0",
      },
      "& h1": {
        fontWeight: 700,
        fontSize: 34,
        lineHeight: "43px",
        color: "#474748",
        wordBreak: "break-all",
        [theme.breakpoints.down("sm")]: {
          fontSize: 32,
          lineHeight: "1.3",
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "19px",
        color: "#474748",
        margin: "16px 0",
      },
    },
    collectionBannerAction: {
      display: "flex",
      margin: 0,
      padding: 0,
      justifyContent: "center",
      listStyle: "none",
      alignItems: "center",
      "& li": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "17px",
        textTransform: "capitalize",
        color: "#fff",
        position: "relative",
        padding: "0 10px",
        "&:first-child:after": {
          content: '""',
          width: 1,
          height: "100%",
          background: "#fff",
          position: "absolute",
          right: "0",
        },
        "&:last-child": {
          padding: "0",
        },
        "& span": {
          fontWeight: 400,
          fontSize: 14,
          lineHeight: "17px",
          textTransform: "capitalize",
          color: "#fff",
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          display: "inline-block !important",
          verticalAlign: "super",
        },
        "& img": {
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          display: "inline-block !important",
        },
      },
    },
    shareModal: {
      background: "#FFFFFF",
      borderRadius: 12,
      width: 512,
      padding: 24,
    },
    shareModalTitle: {
      position: "relative",
      "& h5": {
        textTransform: "capitalize",
        fontWeight: 700,
        fontSize: 18,
        lineHeight: "22px",
      },
      "& button": {
        position: "absolute",
        right: 0,
        top: 0,
        "& svg": {
          color: "#505B63",
        },
      },
    },
    eventLinkShare: {
      margin: "16px 0",
      "& form": {
        width: "100%",
        position: "relative",
        "& > div": {
          width: "100%",
        },
        "& .MuiFilledInput-underline": {
          "&:before": {
            display: "none",
          },
        },
        "& input": {
          border: "1px solid #CCD4DA",
          background: "#EEF3F7",
          padding: "12px 90px 10px 8px",
          color: "#505B63",
          fontSize: 16,
          lineHeight: "19px",
          borderRadius: 8,
        },
        "& button": {
          position: "absolute",
          right: 4,
          top: 4,
          background: "#FFEFEA",
          border: "1px solid #CCD4DA",
          borderRadius: 8,
          padding: 8,
          color: "#F96634",
          fontSize: 14,
          lineHeight: "17px",
          textTransform: "capitalize",
        },
      },
    },
    shareLinks: {
      display: "flex",
      justifyContent: "center",
      listStyle: "none",
      margin: 0,
      "& li": {
        margin: "0 8px",
        "& a": {
          width: 40,
          height: 40,
          borderRadius: "50%",
          background: "#6D7A84",
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    breadcrumbMain: {
      "& .MuiBreadcrumbs-separator": {
        fontSize: 16,
        marginLeft: 2,
        marginRight: 2,
      },
      "& ol": {
        "& li": {
          "& p": {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "19px",
            textTransform: "capitalize",
            color: "#FFFFFF",
          },
          "& svg": {
            color: "#FFFFFF",
          },
          "& a": {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "19px",
            textTransform: "capitalize",
            color: "#FFFFFF",
            textDecoration: "none",
          },
        },
      },
    },
    breadcrumbCollection: {
      "& .MuiBreadcrumbs-separator": {
        fontSize: 16,
        marginLeft: 2,
        marginRight: 2,
      },
      "& ol": {
        "& li": {
          "& p": {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "19px",
            textTransform: "capitalize",
            color: "#474748",
          },
          "& svg": {
            color: "#474748",
          },
          "& a": {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "19px",
            textTransform: "capitalize",
            color: "#474748",
            textDecoration: "none",
          },
        },
      },
    },
    breadcrumbMainDark: {
      "& .MuiBreadcrumbs-separator": {
        fontSize: 16,
        marginLeft: 2,
        marginRight: 2,
      },
      "& ol": {
        "& li": {
          "& p": {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 1.5,
            textTransform: "inherit",
            color: "#505B63",
          },
          "& svg": {
            color: "#505B63",
          },
          "& a": {
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "27px",
            textTransform: "capitalize",
            color: "#505B63",
            textDecoration: "none",
          },
        },
      },
    },
    breadcrumbMainDark2024: {
      "& .MuiBreadcrumbs-separator": {
        fontSize: 16,
        marginLeft: 2,
        marginRight: 2,
      },
      "& ol": {
        "& li": {
          "& p": {
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 1.5,
            textTransform: "inherit",
            color: "#fff",
          },
          "& svg": {
            color: "#fff",
          },
          "& a": {
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "27px",
            textTransform: "capitalize",
            color: "#fff",
            textDecoration: "none",
          },
        },
      },
    },
  }));
};

export const nomineeDetail = () => {
  return makeStyles((theme) => ({
    nomineeBox: {
      maxWidth: "970px",
      margin: "-220px auto 0",
    },
    nomineeBoxContent: {
      background: "#FFFFFF",
      boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.2)",
      borderRadius: 12,
      padding: 24,
      "& photo": {
        width: "100%",
      },
      "& lines": {
        width: "100%",
      },
    },
    nomineeSlider: {
      position: "relative",
    },
    NomineeWinner: {
      width: "100px !important",
    },
    // nomineeVote: {
    //   background: 'linear-gradient(180deg, #BBBBBB 8.09%, #DDDDDD 20.31%, #FFFFFF 95.36%)',
    //   borderRadius: 20,
    //   padding: '8px 16px',
    //   position: 'absolute',
    //   left: 16,
    //   top: 16,
    //   zIndex: 1,
    //   fontWeight: 700,
    //   fontSize: 16,
    //   lineHeight: '19px',
    //   color: '#2B2E31',
    // },
    nomineeDetailSlide: {
      borderRadius: 12,
      overflow: "hidden",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    Nomineecontent: {
      padding: "32px 0",
      "& h1": {
        fontSize: 32,
        lineHeight: "38px",
        marginBottom: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 24,
          lineHeight: "1.5",
        },
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
        marginBottom: 0,
      },
    },
    Nomineecontenttitle: {
      display: "flex",
      //alignItems: 'baseline',
      justifyContent: "space-between",
      gap: 40,
      "& a": {
        flex: 1,
        background: "#EEF3F7",
        borderRadius: 16,
        padding: "8px 12px",
        display: "flex",
        alignItems: "center",
        gap: 12,
        fontSize: 14,
        lineHeight: "17px",
        color: "#F96634",
        maxWidth: 168,
      },
    },
    mainWrapContent: {
      "& img": {
        width: "100% !important",
        height: "auto !important",
      },
    },
    nomineeDList: {
      borderTop: "1px solid #C2D0DC",
      padding: "20px 4px",
    },
    nomineeDListBox: {
      marginBottom: 24,
      "& span": {
        display: "block",
        fontSize: 18,

        lineHeight: "22px",
        marginBottom: 16,
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
      },
      "& ul": {
        display: "flex",
        listStyle: "none",
        flexWrap: "wrap",
        paddingLeft: 0,
        margin: 0,
        gap: 16,
        "& li": {
          fontSize: 16,
          lineHeight: "19px",
          color: "#606970",
          textTransform: "capitalize",
          position: "relative",
          paddingLeft: 24,
          "&::before": {
            content: '""',
            width: 12,
            height: 12,
            background: "#606970",
            borderRadius: "50%",
            display: "inline-block",
            position: "absolute",
            left: 0,
            top: 4,
          },
        },
      },
    },
    nomineeshare: {
      display: "flex",
      padding: "24px 4px",
      // borderBottom: '1px solid #C2D0DC',
      alignItems: "center",
      position: "fixed",
      right: "13%",
      top: 190,
      zIndex: "99",
      "& span": {
        display: "block",
        fontSize: 18,
        lineHeight: "22px",
      },
      "& ul": {
        display: "flex",
        listStyle: "none",
        margin: 0,
        paddingLeft: 0,
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          paddingLeft: 0,
          paddingTop: 20,
        },
        "& li": {
          "& button": {
            "& img": {
              width: 48,
              display: "inline-block",
              [theme.breakpoints.down("sm")]: {
                width: 35,
              },
            },
            "& svg": {
              [theme.breakpoints.down("sm")]: {
                width: 35,
                height: 35,
              },
            },
          },
          "& img": {
            width: 48,
            display: "inline-block",
            [theme.breakpoints.down("sm")]: {
              width: 35,
            },
          },
        },
      },
    },
    nomineeDetailAction: {
      paddingTop: 40,
      "& button": {
        flex: "0 0 auto",
        width: "100%",
        padding: "14px 20px",
        lineHeight: "23px",
        borderRadius: "64px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      "& a": {
        flex: "0 0 auto",
        width: "100%",
        padding: "14px 20px",
        lineHeight: "23px",
        borderRadius: "64px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      "& .MuiButton-containedPrimary": {
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
        marginLeft: 16,
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          marginTop: 16,
        },
      },
    },
    modalVoteStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    voteModal: {
      background: "#FFFFFF",
      borderRadius: "12px",
      position: "relative",
      minWidth: 656,
      maxWidth: 656,
      [theme.breakpoints.down("1370")]: {
        marginTop: 62,
        overflowY: "scroll",
        height: 580,
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        padding: 24,
      },
      "& button": {
        flex: "0 0 auto",
        width: "100%",
        padding: "14px 20px",
        lineHeight: "23px",
        borderRadius: "64px",
        marginTop: 16,
      },
      "& .MuiButton-containedPrimary": {
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
      },
    },
    voteModalClose: {
      top: 3,
      right: 3,
      position: "absolute",
      width: "10px !important",
      "&:hover": {
        borderRadius: 0,
      },
    },
    voteHeader: {
      textAlign: "center",
    },
    voteThanks: {
      width: "100%",
    },
    voteAuth: {
      width: "100%",
      marginTop: 20,
    },
    voteBox: {
      padding: "0 24px 24px",
      "& h2": {
        fontSize: 32,
        lineHeight: "38px",
        marginBottom: 16,
        marginTop: 16,
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
        marginBottom: 0,
        width: 350,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        margin: "0 auto",
      },
      "& form": {
        marginTop: 20,
        textAlign: "left",
      },
    },
    voteInput: {
      height: 56,
      padding: "8px 8px 8px 16px",
      background: "#FFFFFF",
      border: "1px solid #CCD4DA",
      borderRadius: 8,
      fontSize: 14,
      lineHeight: "24px",
      color: "#000",
      display: "block",
      width: "100%",
      marginTop: 16,
    },
    voteForm: {
      "& .error": {
        color: "#f2545b",
        fontSize: 14,
      },
    },
    voteCaptcha: {
      marginBottom: 13,
    },
    nomineeVoting: {
      maxWidth: 970,
      background: "rgba(255, 255, 255, 0.1)",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(10px)",
      padding: "16px",
      borderRadius: "16px",
      border: "1px solid rgba(255, 255, 255, 0.25)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 24,
      color: "#fff",
      "& h2": {
        fontWeight: 700,
        fontSize: 20,
        lineHeight: "24px",
      },
    },
    nomineeVoteTimer: {
      display: "flex",
      gap: 7,
      "& p": {
        margin: "0 10px 0 0",
        fontWeight: 750,
        fontSize: 20,
        lineHeight: "24px",
        padding: "8px 16px",
        position: "relative",
        borderRadius: 8,
        border: "1px solid rgba(255, 255, 255, 0.25)",
        background: "rgba(255, 255, 255, 0.10)",
        "& span": {
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "16px",
        },
        "&:last-of-type": {
          "&::after": {
            display: "none",
          },
        },
        "&::after": {
          content: '":"',
          position: "absolute",
          right: "-12px",
        },
      },
    },
    moreCatBox: {
      marginTop: 100,
      "& h2": {
        fontSize: 36,
        lineHeight: "43px",

        marginBottom: 40,
        paddingBottom: 16,
        borderBottom: "1px solid #C2D0DC",
      },
    },
    voteStickybtn: {
      background: "rgba(255, 255, 255, 0.8)",
      boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.2)",
      backdropFilter: "blur(2.5px)",
      padding: "16px",
      textAlign: "center",
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: 99,
      "& button": {
        textTransform: "capitalize",
        background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
        padding: "16px 60px",
        minWidth: 512,
        boxShadow: "none",
        borderRadius: 64,
        fontSize: 16,
        lineHeight: "19px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%",
        },
      },
    },
  }));
};

export const topicCardSingleStyles = () => {
  return makeStyles((theme) => ({
    card: {
      borderRadius: "1rem",
      boxShadow: "none",
      position: "relative",
      minWidth: 340,
      width: 340,
      minHeight: 255,
      margin: "20px 12px",
      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        width: "100%",
        height: "64%",
        bottom: 0,
        zIndex: 1,
        background: "linear-gradient(to top, #000, rgba(0,0,0,0))",
      },
      "& > div": {
        backgroundSize: "auto",
      },
    },
    content: {
      // position: 'absolute',
      zIndex: 2,
      bottom: 0,
      width: "100%",
    },

    topicSection: {
      backgroundImage: "url(" + browse_by_topic_bg + ")",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      overflow: "hidden",
    },
    cardWrap: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    bgSection: {
      // backgroundImage: 'url(' + browse_by_topic_bg + ')',
      padding: "40px 0",
    },
    topicTitle: {
      color: "#000",
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: "19px",
    },
    eventTopics: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        display: "block",
        textAlign: "center",
      },
    },
    eventTopicBox: {
      display: "inline-flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "117px 48px",
      gap: "16px",
      width: "528px",
      background: "rgba(255, 255, 255, 0.9)",
      border: "1px solid #FFFFFF",
      boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.1)",
      backdropFilter: "blur(20px)",
      borderRadius: "12px",
      flex: "0 0 auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& h2": {
        fontWeight: 700,
        fontsize: 32,
        lineHeight: "38px",
        textTransform: "capitalize",
        color: "#000000",
        [theme.breakpoints.down("md")]: {
          color: "#ffffff",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: 24,
          lineHeight: "29px",
        },
      },
      "& p": {
        fontSize: 18,
        lineHeight: "20px",
        color: "#000000",
        [theme.breakpoints.down("md")]: {
          color: "#ffffff",
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: 0,
        background: "transparent",
        boxShadow: "none",
        border: 0,
        marginBottom: 16,
      },
    },
    topicList: {
      width: "100%",
      display: "inline-block",
      background: "#FFFFFF",
      boxShadow: "0px 20px 20px rgb(0 0 0 / 20%)",
      borderRadius: "12px 0px 0px 12px",
      padding: "16px",
      marginLeft: "-24px",
      zIndex: 1,
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        borderRadius: 12,
        marginLeft: 0,
        "& .slider": {
          display: "flex",
          flexWrap: "wrap",
          paddingBottom: 0,
          "& > a": {
            width: "33.33%",
            flex: "0 0 auto",
          },
        },
      },
      [theme.breakpoints.down("sm")]: {
        borderRadius: 12,
        marginLeft: 0,
        "& .slider": {
          display: "flex",
          flexWrap: "wrap",
          "& > a": {
            width: "50%",
            flex: "0 0 auto",
          },
        },
      },
      "& .slick-slider": {
        width: "100%",
      },
      "& .slick-list": {
        width: "100%",
      },
    },
    browseTopicSignle: {
      width: 200,
      height: 200,
      borderRadius: 12,
      display: "inline-block",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: 140,
        height: 140,
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  }));
};

export const forgotPassStyles = () => {
  return makeStyles((theme) => ({
    loginWrap: {
      backgroundImage:
        "url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1635104952bg-png1635104952.png)",
      padding: "40px 0",
      minHeight: "89vh",
      width: "100%",
      backgroundSize: "cover",
    },

    card: {
      borderRadius: 12,
      minWidth: 256,
      maxWidth: 400,
      textAlign: "left",
      margin: "50px auto 0",
    },
    cardWide: {
      borderRadius: 12,
      minWidth: 256,
      maxWidth: 800,
      textAlign: "left",
      margin: "50px auto 0",
      "& > div": {
        maxWidth: 400,
        margin: "auto",
      },
    },
    heading: {
      fontSize: 24,
      fontWeight: "bold",
      letterSpacing: "0.5px",
      marginTop: 8,
      marginBottom: "0.875em",
      textAlign: "center",
      textTransform: "capitalize",
    },
    subheading: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: 8,
      marginBottom: "0.875em",
      textTransform: "capitalize",
    },
    filterSearchBox: {
      width: "100%",
      border: "1px solid #CCD5DC",
      borderRadius: 5,
      padding: "8px 0 5px 10px",
      fontSize: 14,
      "& svg": {
        color: "#A6B3BE",
      },
    },

    actionCode: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("380")]: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        "& div": {
          marginTop: 20,
        },
      },
    },
    buttonLink: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      marginBottom: "1rem",
      marginTop: "1rem",
      "&::before": {
        content: '""',
        // backgroundImage: 'url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1635109449google-svg1635109449.svg)',
        display: "block",
        height: 28,
        width: 28,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        marginRight: 12,
      },
    },
    buttonGoogle: {
      "&::before": {
        backgroundImage:
          "url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1635109449google-svg1635109449.svg)",
      },
    },
    buttonfb: {
      "&::before": {
        backgroundImage:
          "url(	https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1635110149fb-png1635110149.png)",
      },
    },
    linkText: {
      marginBottom: "1rem",
      marginTop: "1rem",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    forgotLink: {
      display: "flex",
      justifyContent: "flex-end",
      textTransform: "capitalize",
    },
  }));
};

// export const DialogTitle = withStyles(matchMakingModalStyles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//             <Typography variant="h6">{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
// });

// export const StyledBadge = withStyles((theme) => ({

//     badge: {
//         backgroundColor: colors.success.main,
//         color: colors.success.contrastText,
//     },
// }))(Badge);

export const accordianAgendaStyles = () => {
  return makeStyles((theme) => ({
    infoTitle: {
      marginBottom: 40,
      marginTop: 40,

      fontSize: 24,
    },
    main: {
      marginTop: -100,
      paddingTop: 100,
    },
    agendaInfoWrap: {
      border: "1px solid #CCD4DA",
      borderRadius: "8px",
      padding: "24px 24px 14px",
      "& img": {
        maxWidth: "100%",
        height: "auto",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 16,
      },
    },
    agendaInfoWrapTitle: {
      fontSize: 18,

      color: "#000000",
      lineHeight: "22px",
    },
    agendaTime: {
      "& span": {
        paddingTop: 4,
      },
    },
    agendaBoxGrid: {
      paddingLeft: 15,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down("sm")]: {
        "& p, & a": {
          wordBreak: "break-all",
        },
      },
    },
    viewAgendsSpeaker: {
      justifyContent: "center",
      textTransform: "capitalize",
    },
  }));
};

export const awardWinnerCardStyles = () => {
  return makeStyles((theme) => ({
    awwinnerMai: {
      paddingTop: 16,
      [theme.breakpoints.down("md")]: {
        marginTop: 48,
      },
    },
    filtersSection: {
      textAlign: "center",
    },
    filterChip: {
      margin: "30px 10px",
      padding: "20px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 5px",
      },
      "& span": {
        fontSize: "16px",
      },
    },
    awWinMain: {
      border: "1px solid var(--Neutrals-Gray-100, #E2E8ED)",
      borderRadius: "12px",
      background: "#FFF",
      boxShadow: "0px 4px 4px 0px rgba(192, 152, 183, 0.25)",
      padding: "0 !important",
      margin: "15px 5px",
      maxWidth: "32%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "inherit",
      },
    },
    // awWin: {
    //   marginTop: 32,
    //   [theme.breakpoints.down('md')]: {
    //     marginTop: 0,
    //   }
    // },
    awWinT: {
      position: "relative",
    },
    awWinImg: {
      borderRadius: 12,
      height: "200px",
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        objectFit: "conver",
      },
      // '&:after': {
      //   content: '""',
      //   position: 'absolute',
      //   bottom: 0,
      //   display: 'block',
      //   width: '100%',
      //   height: '100%',
      //   background: 'rgba(0,0,0,0.5)',
      //   zIndex: 0,
      //   borderRadius: 12,
      //   opacity: 0.7,
      //   transition: 'opacity 0.4s ease'
      // },
    },
    awWinOrgTitle: {
      display: "flex",
      alignItems: "center",
      gap: 12,
      "& p": {
        color: "#fff",
        fontWeight: "700",
        fontSize: 14,
        lineHeight: "17px",
      },
    },
    awWinIcon: {
      width: "60px",
      height: "60px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      "& img": {
        width: "100% !important",
        height: "100%",
        objectFit: "contain",
        // borderRadius: "4px",
        // border: '1px solid #ccc',
      },
    },
    awWinOrgLink: {
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.2)",
      borderRadius: "8px",
      color: "#fff",
      "&:hover": {
        color: "#fff",
      },
    },
    ChipawWinText: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      padding: "16px",
      alignItems: "baseline",
      "& p": {
        fontWeight: "400",
        fontSize: 14,
        lineHeight: "19px",
        color: "#3F4850",
      },
      "& img": {
        width: "16px",
        marginRight: "8px",
        verticalAlign: "bottom",
      },
      "& h2": {
        fontWeight: "700",
        fontSize: 18,
        lineHeight: "22px",
        color: "#000",
      },
    },
  }));
};

export const oldWinnerStyles = () => {
  return makeStyles((theme) => ({
    lWinner: {
      background: "#FFEFEA",
      borderRadius: "32px",
      padding: "48px 48px 56px",
      marginTop: "102px",
      [theme.breakpoints.down("md")]: {
        padding: "24px",
        marginTop: "52px",
      },
    },
    lWinnerText: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      gap: 16,
      alignItems: "center",
      maxWidth: "960px",
      margin: "0 auto 48px",
      "& h2": {
        fontWeight: 700,
        fontSize: 48,
        lineHeight: "58px",
        color: "#000000",
        [theme.breakpoints.down("md")]: {
          fontSize: 24,
          lineHeight: "1.5",
        },
      },
      "& p": {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "22px",
        color: "#000000",
      },
      "& a": {
        color: "#F96634",
        fontSize: 16,
        fontWeight: "bold",
        marginLeft: 25,
        "& svg": {
          verticalAlign: "middle",
        },
      },
      "& button": {
        background: "#F96634",
        fontSize: 16,
        textTransform: "capitalize",
        lineHeight: "19px",
        padding: "12px 16px",
      },
    },
    awLogoRow: {
      display: "flex",
      gap: 16,
      justifyContent: "center",
      marginBottom: 16,
      flexWrap: "wrap",
    },
    awlogobox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 0",
      gap: 10,
      width: 180,
      height: 64,
      background: "#FFFFFF",
      boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.2)",
      borderRadius: "12px",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
      [theme.breakpoints.down("md")]: {
        width: "30%",
        height: 54,
      },
    },
  }));
};

export const onboardingStyles = () => {
  return makeStyles((theme) => ({
    obLogin: {
      //background: "#F1F5F8",
      height: "calc(100vh - 64px)",
      display: "flex",
      alignItems: "center",
      backgroundImage:
        "url(https://vepimg.b8cdn.com/uploads/vjfnew//content/files/1635104952bg-png1635104952.png)",
      padding: "40px 0",
      //minHeight: '89vh',
      width: "100%",
      backgroundSize: "cover",
    },
    obLoginBox: {
      background: "#FFFFFF",
      borderRadius: "12px",
      maxWidth: 484,
      padding: 32,
      margin: "0 auto",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
      "& h2": {
        fontWeight: 700,
        fontSize: 32,
        lineHeight: "38px",
        textAlign: "center",
        color: "#000000",

        marginBottom: 16,
        [theme.breakpoints.down("sm")]: {
          fontSize: 26,
          marginBottom: 12,
        },
      },
      "& > p": {
        fontSize: 18,
        lineHeight: "22px",
        textAlign: "center",
        color: "#3F4850",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
    obLoginForm: {
      margin: "24px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "18px 0",
      },
      "& input": {
        margin: 0,
      },
      "& span.error": {
        color: "red",
        float: "left",
      },
      "& button": {
        width: "100%",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "19px",

        height: 51,
        borderRadius: 30,
        marginTop: 15,
      },
    },
    input: {
      height: 56,
      background: "#FFFFFF",
      border: "1px solid #CCD4DA",
      borderRadius: "8px",
      padding: "8px 8px 8px 16px",
      width: "100%",
      fontSize: 14,
      lineHeight: "24px",
      color: "#505B63",
      marginBottom: 16,
      "&:focus-visible": {
        outline: 0,
      },
    },
    forgotPassword: {
      marginBottom: 16,
      textAlign: "right",
      "& a": {
        fontSize: 14,
        lineHeight: "17px",
        color: "#F96634",

        display: "inline-table",
      },
    },
    alreadyAccount: {
      textAlign: "center",
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        textAlign: "center",
        color: "#000000",
        marginBottom: 16,
      },
      "& a": {
        fontSize: 16,
        lineHeight: "19px",
        color: "#F96634",
      },
    },
    headercTop: {
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))",
      padding: "15px 0",
    },
    obNavbarBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 24,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "end",
      },
      "& p": {
        fontWeight: 400,
        fontSize: 28,
        lineHeight: "34px",
        textSlign: "center",
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "#000000",
        [theme.breakpoints.down("sm")]: {
          fontSize: 22,
          display: "none",
        },
      },
    },
    backwebsite: {
      fontSize: 16,
      lineHeight: "26px",
      textTransform: "capitalize",
      color: "#6D7A84",
      padding: 0,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& svg": {
        fontSize: "26px !important",
        verticalAlign: "bottom",
      },
    },
    eventSteps: {
      background: "#E5E5E5",
      minHeight: "100vh",
    },
    rOverViewGrid: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    stepOverview: {
      background: "linear-gradient(180deg, #F96634 0%, #EF4678 100%)",
      paddingRight: 0,
      height: "100vh",
      //height: "100%",
      display: "flex",
      alignItems: "center",
      // [theme.breakpoints.down('lg')]: {
      //   height: "100vh"
      // },
      [theme.breakpoints.down("md")]: {
        paddingRight: 0,
        height: "auto",
        marginBottom: 32,
      },
      "& h2": {
        fontWeight: 500,
        fontSize: 32,
        lineHeight: "38px",
        color: "#FFFFFF",
        margin: "24px 0 16px",
        [theme.breakpoints.down("sm")]: {
          fontSize: 24,
          margin: "18px 0 6px",
        },
      },
      "& p": {
        fontSize: 20,
        lineHeight: "24px",
        color: "#FFFFFF",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
    stepOverviewContent: {
      width: "100%",
      padding: "0px 40px",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "24px",
      },
      "& span": {
        fontSize: 60,
        fontWeight: "900",
        display: "block",
        marginBottom: 25,
      },
      "& img": {
        maxWidth: "100%",
        height: "auto",
      },
    },
    eventStepsC: {
      margin: "15px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "26px 0",
      },
    },
    eventStepGuide: {
      background: "#FEF0EB",
      borderRadius: 8,
      padding: 8,
      display: "inline-flex",
      gap: 6,
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        padding: 12,
      },
      "& span": {
        width: 20,
        height: 20,
        border: "2px solid #FDC2AE",
        fontSize: 14,
        lineHeight: "17px",
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "#F96634",

        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&.quarter": {
          "&:after": {
            content: "''",
          },
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "24px",
        letterSpacing: "0.2px",
        textTransform: "capitalize",
        color: "#F96634",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
    eventStepTitle: {
      margin: "20px 0 0",

      fontSize: 30,
      lineHeight: "30px",
      //letterSpacing: '0.2px',
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        fontSize: 32,
        margin: "16px 0",
        lineHeight: "1.2",
      },
    },
    eventSelectionLabel: {
      fontSize: 18,
      lineHeight: "24px",
      //letterSpacing: '0.2px',
      color: "#505B63",
      marginBottom: 24,
      display: "block",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    eventCheck: {
      background: "#FFFFFF",
      border: "1px solid #E5E7EB",
      borderRadius: "1000px",
      display: "flex",
      width: 26,
      height: 26,
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: 15,
      "& svg": {
        fill: "#F96634",
        width: "28px",
        height: "28px",
        display: "none",
      },
    },
    eventModeSelection: {
      marginRight: 0,
      marginLeft: 0,
      width: "100%",
      marginBottom: 16,
      position: "relative",
      // '&:not(:last-of-type)': {
      //   marginBottom: 16
      // },
      "& .MuiFormControlLabel-label": {
        background: "#fff",
        border: "1px solid #B5B8BE",
        borderRadius: "12px",
        padding: "12px 40px 12px 8px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 52px 20px 20px",
        },
      },

      "& .MuiRadio-root": {
        opacity: 0,
        position: "absolute",
        "&.Mui-checked": {
          "& ~ .MuiFormControlLabel-label": {
            border: "1px solid #F96634",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
            "& h2": {
              color: "#F96634",
            },
            "& > div": {
              "& > span": {
                border: 0,
                "& svg": {
                  display: "flex",
                },
              },
            },
          },
        },
      },
    },
    eventTypeSelection: {
      marginRight: 0,
      marginLeft: 0,
      width: "100%",
      marginBottom: 16,
      position: "relative",
      background: "#fff",
      border: "1px solid #B5B8BE",
      borderRadius: "12px",
      padding: "12px 40px 12px 8px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 52px 20px 20px",
      },
      "& .MuiFormControlLabel-label": {
        display: "none",
      },
      "& .MuiCheckbox-root": {
        right: 5,
        top: 15,
        position: "absolute",
        color: "#F96634",
      },
      "& .MuiRadio-root": {
        opacity: 0,
        position: "absolute",
        "&.Mui-checked": {
          "& ~ .MuiFormControlLabel-label": {
            border: "1px solid #F96634",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
            "& h2": {
              color: "#F96634",
            },
            "& > div": {
              "& > span": {
                border: 0,
                "& svg": {
                  display: "flex",
                },
              },
            },
          },
        },
      },
    },
    eventMContent: {
      display: "flex",
      gap: 10,
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        gap: 16,
      },
      "& h2": {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: "29px",
        color: "#5F6269",
        marginBottom: 4,
        [theme.breakpoints.down("sm")]: {
          fontSize: 20,
        },
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#5F6269",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
    twoRowContent: {
      flexDirection: "row",
      gap: 6,
      height: 425,
      overflowY: "auto",
      paddingRight: 16,
      marginBottom: 16,
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#E5E5E5",
      },

      "&::-webkit-scrollbar": {
        width: 6,
        backgroundColor: "#E5E5E5",
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D9D9D9",
      },

      "& > *": {
        width: "calc(50% - 8px)",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        marginBottom: 0,
        "& h2": {
          fontSize: 16,
          lineHeight: "16px",
          //letterSpacing: '0.2px',
          color: "#000000",
          textTransform: "capitalize",
          [theme.breakpoints.down("sm")]: {
            fontSize: 18,
          },
        },
        "& p": {
          fontSize: 14,
          lineHeight: "16px",
          //letterSpacing: '0.2px',
          color: "#505B63",
        },
        "& > span": {
          "&:last-of-type": {
            "& > div": {
              gap: 6,
            },
          },
          "&.Mui-checked": {
            "& ~ .MuiFormControlLabel-label": {
              boxShadow: "none !important",
              "& h2": {
                color: "#000 !important",
              },
            },
          },
        },
      },
    },
    backbtn: {
      padding: 0,
      fontSize: 16,
      lineHeight: "19px",
      textTransform: "uppercase",
    },
    bbuttons: {
      display: "flex",
      gap: 26,
      alignItems: "center",
    },
    stepContenttwo: {
      textAlign: "center",
      maxWidth: 400,
      marginLeft: "auto",
      marginRight: "auto",
      "& p": {
        marginTop: 24,
      },
    },
    onbaordWrap: {
      //maxWidth: 960,
      margin: "0 auto",
    },
    eventdetailTitle: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "38px",
      letterSpacing: "0.2px",

      marginBottom: 16,
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    eventDetailcount: {
      fontSize: 20,
      lineHeight: "24px",
      color: "#5F6269",
    },
    eventCountBoxes: {
      flexDirection: "row",
      gap: 16,
      marginBottom: 24,
      "& > *": {
        width: "calc(50% - 8px)",
        marginBottom: 0,
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        "& > span": {
          "&:last-of-type": {
            padding: "16px 42px 16px 16px",
            borderColor: "transparent",
            "& > div": {
              gap: 16,
            },
          },
          "&.Mui-checked": {
            "& ~ .MuiFormControlLabel-label": {
              boxShadow: "none !important",
            },
          },
        },
      },
    },
    inputLabel: {
      fontSize: 16,
      lineHeight: "19px",
      color: "#000000",
      marginBottom: 8,
      display: "block",
    },

    inputGroup: {
      marginBottom: 24,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
      },
      "& > div": {
        width: "100%",
      },
      "& .MuiInputBase-root": {
        borderRadius: 8,
        fontSize: 14,
        lineHeight: "17px",
        color: "#505B63",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
      "& input": {
        background: "#FFFFFF",
        border: "1px solid #CCD4DA",
        borderRadius: 8,
        padding: "11px 11px 11px 16px",
      },
    },
    lastInputGroup: {
      marginBottom: 24,
    },
    eventFormat: {
      fontSize: 16,
      lineHeight: "19px",
      color: "#5F6269",
    },
    eventDoes: {
      flexDirection: "row",
      gap: 16,
      marginBottom: 40,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
      "& > *": {
        width: "calc(20% - 14px)",
        [theme.breakpoints.down("md")]: {
          width: "calc(20% - 14px)",
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        marginBottom: 0,
        "& > span": {
          "&:last-of-type": {
            padding: "10px 42px 10px 16px",
            borderColor: "#C2D0DC",
            "& > div": {
              gap: 16,
            },
          },
          "&.Mui-checked": {
            "& ~ .MuiFormControlLabel-label": {
              boxShadow: "none !important",
            },
          },
        },
      },
    },
    eventProgressBar: {
      height: 2,
      background: "#F96634",
      position: "absolute",
      bottom: "-1px",
    },
    signupModalBox: {
      background: "#FFFFFF",
      position: "relative",
      borderRadius: 12,
      padding: "32px",
      width: 512,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        padding: 16,
        width: "100%",
      },
    },
    closebtnModal: {
      marginBottom: 14,
      textAlign: "right",
      "& > button": {
        padding: 0,
        fontSize: 24,
        "& svg": {
          color: "#505B63",
        },
      },
    },
    // signupModal: {
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // },
    // signupMBox: {
    //   '& h2': {
    //
    //     margin: "16px 0",
    //     fontSize: 32,
    //     lineHeight: '38px',
    //     color: '#000000'

    //   },
    //   createFirstEvent: {
    //     fontSize: 18,
    //     lineHeight: '22px',
    //     color: '#3F4850',
    //     marginBottom: 24
    //   }
    // },
    signupForm: {
      flexDirection: "row",
      margin: "24px 0",
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& input": {
        background: "#FFFFFF",
        padding: "19px 19px 19px 16px",
        border: "1px solid #CCD4DA",
        borderRadius: 8,
        fontSize: 14,
        lineHeight: "24px",
        color: "#505B63",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
      "& button": {
        marginTop: 16,
        width: "100%",
        fontSize: 16,
        lineHeight: "19px",

        padding: "16px 12px",
        borderRadius: 30,
      },
    },
    alreadyAccounts: {
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#000000",
        marginBottom: 16,
      },
      "& a": {
        fontSize: 16,
        lineHeight: "19px",
        textalign: "center",
        textTransform: "uppercase",
        color: "#F96634",
      },
    },
  }));
};

const drawerWidth = 238;

export const dashboardStyles = () => {
  return makeStyles((theme) => ({
    main: {
      background: "#E5E5E5",
      padding: 16,
      minHeight: "100vh",
    },
    header: {
      boxShadow: "none",
      borderRadius: 16,
      margin: "3px 0 19px",
      padding: "12px 0",
    },
    innerGutterHeader: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    searchBarMobile: {
      border: 0,
      backgroundColor: "#F1F5F8",
      paddingLeft: 16,
      borderRadius: 80,
      [theme.breakpoints.down("xs")]: {
        display: "none",
        transition: "0.5s",
        position: "absolute",
        left: 0,
        zIndex: 99,
      },
      "& input": {
        padding: 17,
      },
    },
    searchBarToggle: {
      bottom: "-41px",
      display: "inline-flex",
    },
    profileUName: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    notification: {
      "& button": {
        padding: 0,
        minWidth: "auto",
        width: 48,
        height: 48,
        background: "#F1F5F8",
        borderRadius: 96,
      },
    },
    dropdownStyles: {
      "& .MuiPopover-paper": {
        background: "#FFFFFF",
        boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.2)",
        borderRadius: 8,
        padding: 8,
        minWidth: 161,
      },
      "& ul a:hover": {
        textDecoration: "none",
      },
      "& ul": {
        padding: 0,
        "& li": {
          padding: 16,
          fontWeight: 500,
          fontSize: 14,
          lineHeight: "17px",
          color: "#000000",
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&:not(:last-of-type)": {
            borderBottom: "1px solid #E2E8ED",
          },
        },
      },
    },
    profile: {
      marginLeft: 16,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 8,
      },
      "& button": {
        padding: 8,
        minWidth: "auto",
        background: "#F1F5F8",
        borderRadius: 8,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "19px",
        textTransform: "capitalize",
        color: "#000000",

        "& img": {
          marginRight: 8,
          width: 32,
          height: 32,
        },
      },
    },

    wrapper: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      top: 128,
      left: 16,
      bottom: 16,
      background: "#1B1F29",
      borderRadius: 16,
      padding: 16,
      height: "auto",
      position: "absolute",
      "& .MuiList-padding": {
        padding: "0",
        "& .MuiListItem-button": {
          fontWeight: 500,
          fontSize: 14,
          lineHeight: "17px",
          color: "#FFFFFF",
          opacity: "0.5",
          borderRadius: 8,
          padding: "12px 8px",
          marginBottom: 8,
          // transition: "0.3s ease",
          "&:hover": {
            background: "rgba(249, 102, 52, 0.1)",
            color: "#F96634",
            opacity: "1",
            "& svg": {
              "& circle, & path": {
                stroke: "#F96634",
              },
            },
          },
        },
        "& .MuiListItemText-root": {
          margin: 0,
        },
      },
    },
    activeItem: {
      background: "rgba(249, 102, 52, 0.1)",
      color: "#F96634 !important",
      opacity: "1 !important",
      "& svg": {
        "& circle, & path": {
          stroke: "#F96634",
        },
      },
    },
    content: {
      flexGrow: 1,
      padding: "0px 0 0 24px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    filter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 24,
      "& h1": {
        fontSize: 24,
        lineHeight: "29px",
        color: "#15131E",
      },
      "& .searchFilter": {
        minWidth: 220,
        width: 220,
        "& .MuiInputBase-root": {
          backgroundColor: "#fff",
        },
        "& input": {
          padding: 12,
        },
        "& img": {
          width: 17,
        },
      },
      "& .MuiButton-containedPrimary": {
        color: "#ffffff",
        padding: "10px 20px",
        boxShadow: "none",
        borderRadius: 30,
      },
    },
    filterItems: {
      maxWidth: 1000,
      display: "flex",
      alignItems: "center",
      gap: 16,
      "& a:hover": {
        textDecoration: "none",
      },
    },
    filterMenu: {
      "& button": {
        background: "#FFFFFF",
        border: "1px solid #E2E8ED",
        borderRadius: "96px",
        padding: "8.5px 20px",
        minWidth: 160,
        justifyContent: "space-between",
        textTransform: "capitalize",
        "&:hover": {
          background: "#FFFFFF",
        },
        "& svg": {
          fill: "#A6B3BE",
        },
      },
    },
    eListing: {
      background: "#FFFFFF",
      borderRadius: "16px",
      overflow: "hidden",
    },
    eListingTab: {
      backgroundColor: "transparent",
      color: "#000",
      "& button": {
        opacity: 1,
        minWidth: 140,
        justifyContent: "start",
        padding: "13px 16px",
        textTransform: "capitalize",

        "& .MuiTab-wrapper": {
          width: "auto",
        },
        "&.Mui-selected": {
          color: "#F96634",
        },
      },
    },
    eListingTable: {
      borderTop: "1px solid #E2E8ED",
      "& th": {
        fontSize: 14,
        lineHeight: "17px",
        textTransform: "capitalize",
        color: "#6D7A84",
      },
      "& td": {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "17px",
        textTransform: "capitalize",
        color: "#000000",
        "& .chip": {
          padding: "4px 12px",
          color: "#fff",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
          letterSpacing: "0.16px",
          display: "inline-block",
          borderRadius: 16,
          "&.green": {
            background: "#4EB164",
          },
          "&.orange": {
            background: "#ED6C02",
          },
        },
      },
    },
    eListingTitle: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },
    eListingTImg: {
      borderRadius: 8,
      width: 96,
      height: 50.48,
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "inherit",
      },
    },
    eListingAction: {
      display: "flex",
      justifyContent: "end",
      gap: 16,
    },
    eListingDropdown: {
      "& button": {
        width: 32,
        height: 32,
        background: "#F1F5F8",
        borderRadius: "96px",
        padding: 0,
        minWidth: 32,
      },
    },
    ctEvent: {
      maxWidth: "1100px",
      margin: "auto",
    },
    ctEventBG: {
      background: "#FFFFFF",
      borderRadius: 16,
      padding: 24,
      [theme.breakpoints.down("xs")]: {
        padding: 16,
      },
    },
    ctEventHead: {
      textAlign: "center",
      marginBottom: 24,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 12,
      },
      "& h1": {
        margin: "16px 0",
        fontSize: 32,
        lineHeight: "32px",
        color: "#15131E",
        [theme.breakpoints.down("xs")]: {
          fontSize: 22,
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "19px",
        letterSpacing: "0.2px",
        color: "#6D7A84",
      },
    },
    ctEventList: {
      background: "#FFFFFF",
      borderRadius: 16,
      padding: 16,
      display: "flex",
      gap: 16,
      marginBottom: 24,
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        marginBottom: 8,
      },
      "&.current": {
        background: "#FEF0EB",
        "& span": {
          background: "#F96634",
        },
        "& h2": {
          color: "#000000",
        },
      },
      "&.visited": {
        "& span": {
          background: "#F96634",
        },
        "& h2": {
          color: "#000000",
        },
      },
      "& span": {
        background: "#CCD4DA",
        borderRadius: 96,
        fontSize: 20,
        lineHeight: "24px",
        letterSpacing: "0.2px",
        color: "#FFFFFF",
        display: "flex",
        width: 48,
        height: 48,
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
          width: 36,
          height: 36,
          fontSize: 16,
        },
      },
      "& div": {
        flex: 1,
      },
      "& h2": {
        fontSize: 20,
        lineHeight: "24px",
        letterSpacing: "0.2px",
        color: "#6D7A84",
        fontWeight: 400,
        marginBottom: 8,
        [theme.breakpoints.down("xs")]: {
          fontSize: 17,
          lineHeight: "22px",
          marginBottom: 0,
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "19px",
        letterSpacing: "0.2px",
        color: "#6D7A84",
        fontWeight: 400,
        [theme.breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
    },
    ctEventFooter: {
      background: "#EEF3F7",
      borderRadius: 16,
      padding: "24px 0",
    },
    ctEventFWrap: {
      maxWidth: 1130,
      padding: "0 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 16,
      },
      "& div:last-of-type": {
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          width: "100%",
        },
        "& button": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        },
      },
    },
    etEventSave: {
      display: "flex",
      gap: 16,
      alignItems: "center",
    },
    eventInput: {
      width: "100%",
      marginBottom: 24,
      [theme.breakpoints.down("xs")]: {
        marginBottom: 16,
      },
      "& .MuiInputLabel-root": {
        fontSize: 16,
        lineHeight: "19px",
        color: "#000000",
        marginBottom: 8,
        position: "static",
        "& + .MuiInput-formControl": {
          marginTop: 0,
        },
      },
      "& input, & .MuiSelect-select": {
        border: "1px solid #CCD4DA",
        borderRadius: 8,
        padding: "11px 11px 11px 16px !important",
        fontSize: 14,
        color: "#505B63",
      },
      "& .MuiInput-formControl.Mui-focused": {
        "& input": {
          borderColor: "#F96634",
        },
      },
      "& .class-timezone-dropdown .MuiInput-underline:before, & .class-timezone-dropdown .MuiInput-underline:after, & .MuiAutocomplete-root .MuiInput-underline:before, & .MuiAutocomplete-root .MuiInput-underline:after":
        {
          content: "none",
        },
      "& .MuiAutocomplete-inputRoot": {
        paddingRight: "0 !important",
      },
    },
    eventDoes: {
      flexDirection: "row",
      gap: 8,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 20,
      },
      "& > *": {
        width: "calc(33% - 4px)",
        [theme.breakpoints.down("md")]: {
          width: "calc(33.33% - 12px)",
        },
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        marginBottom: 0,
        "& > span": {
          "&:last-of-type": {
            padding: "10px 42px 10px 16px",
            borderColor: "#C2D0DC",
            "& > div": {
              gap: 16,
            },
          },
          "&.Mui-checked": {
            "& ~ .MuiFormControlLabel-label": {
              boxShadow: "none !important",
            },
          },
        },
      },
    },
    eventTypeSelection: {
      marginRight: 0,
      marginLeft: 0,
      // width: '100%',
      marginBottom: 10,
      position: "relative",
      background: "#fff",
      border: "1px solid #F96634",
      borderRadius: "8px",
      padding: "8px",
      [theme.breakpoints.down("sm")]: {
        padding: "20px 52px 20px 20px",
      },
      "& .MuiFormControlLabel-label": {
        display: "none",
      },
      "& .MuiCheckbox-root": {
        right: 0,
        top: 3,
        position: "absolute",
        color: "#F96634",
      },
      "& .MuiRadio-root": {
        opacity: 0,
        position: "absolute",
        "&.Mui-checked": {
          "& ~ .MuiFormControlLabel-label": {
            border: "1px solid #F96634",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
            "& h2": {
              color: "#F96634",
            },
            "& > div": {
              "& > span": {
                border: 0,
                "& svg": {
                  display: "flex",
                },
              },
            },
          },
        },
      },
    },
    eventMContent: {
      display: "flex",
      gap: 12,
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        gap: 16,
      },
      "& img": {
        width: "35px",
      },
      "& h2": {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: "29px",
        color: "#5F6269",
        marginBottom: 4,
        [theme.breakpoints.down("sm")]: {
          fontSize: 20,
        },
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#5F6269",
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
        },
      },
    },
    ctEventHave: {
      "& label": {
        background: "#FFFFFF",
        border: "1px solid #F96634",
        borderRadius: 8,
        padding: 8,
        width: "100%",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        marginLeft: 0,
        "& .MuiIconButton-root": {
          padding: 0,
          color: "#E5E7EB",
          "&.Mui-checked": {
            color: "#F96634",
          },
        },
      },
    },
    ctEventRLabel: {
      display: "flex",
      gap: 12,
      alignItems: "center",
      "& img": {
        width: 35,
      },
      "& span": {
        fontSize: 14,
        color: "#5F6269",
      },
    },
    ctEventPriceType: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "baseline",
      },
      "& .MuiInput-formControl": {
        flex: "1 1 auto",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
    pTypeCheck: {
      display: "flex",
      flexDirection: "row",
      "& label": {
        marginRight: 24,
        fontSize: 16,
        color: "#000",
        "& .MuiIconButton-root": {
          color: "#7B8794",
          "&.Mui-checked": {
            color: "#F96634",
          },
        },
      },
    },
    ctLabelSpace: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    ctEventFeatures: {
      display: "flex",
      gap: 16,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      "& label": {
        background: "#FFFFFF",
        border: "1px solid #CCD4DA",
        borderRadius: 8,
        padding: 8,
        width: "100%",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        marginLeft: 0,
        marginRight: 0,
        "&:not(:first-of-type)": {
          minWidth: 178,
          maxWidth: 178,
          [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            maxWidth: "100%",
          },
        },
      },
    },
    ctEventUpload: {
      border: "1px dashed #D0D5DD",
      borderRadius: 4,
      padding: "16px 24px",
      textAlign: "center",
      "& p": {
        margin: "0 0 10px",
        color: "#6D7A84",
        fontSize: 14,
      },
    },
    imageBox: {
      width: "100%",
      height: "auto",
      margin: "0 auto",
      // [theme.breakpoints.down("sm")]:{
      //   width: 48,
      //   height: 48,
      // },
      "& img": {
        width: "100%",
        height: "200px",
        objectFit: "contain",
      },
    },
    uploadbtn: {
      "& input": {
        display: "none",
      },
      "& .MuiIconButton-root": {
        padding: 0,
        borderRadius: 4,
        background: "#F96634",
        color: "#fff",
        width: 32,
        height: 32,
      },
    },
    ctEventETTitle: {
      borderBottom: "1px solid #CCD5DC",
      paddingBottom: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 24,
      "& h2": {
        fontSize: 20,
        lineHeight: "24px",
        letterSpacing: "0.2px",
        textTransform: "capitalize",

        color: "#000000",
      },
    },
    ctEventExUpload: {
      "& .MuiFormControl-root": {
        marginBottom: 16,
      },
    },
    eventInputUpload: {
      "& .MuiInput-formControl": {
        "& input": {
          paddingRight: 85,
        },
      },
    },
    ctEventLogoUpload: {
      position: "absolute",
      right: 5,
      top: 2,
      "& input": {
        display: "none",
      },
    },
    ctEventDivider: {
      backgroundColor: "#9CA7AF",
      margin: "16px 0 32px",
    },
    ctExnSpon: {
      marginBottom: 51,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalBox: {
      background: "#FFFFFF",
      borderRadius: 12,
      padding: 32,
      position: "relative",
      minWidth: 484,
      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
      },
    },
    modalBoxContent: {
      textAlign: "center",
      "& h2": {
        fontSize: 32,
        lineHeight: "38px",
        color: "#000000",
        margin: "16px 0",
      },
      "& p": {
        fontSize: 18,
        lineHeight: "22px",
        color: "#3F4850",
        margin: "0 0 16px",
      },
      "& button": {
        marginTop: 16,
        width: "100%",
        boxShadow: "none",
        padding: "16px 20px",
        borderRadius: "100px",
        fontSize: 16,
      },
      "& a:hover": {
        textDecoration: "none",
      },
    },
    dashboardTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "8px 0 24px",
      "& h1": {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: "29px",
        color: "#15131E",
      },
    },
    profileBanner: {
      "& img": {
        maxWidth: "100%",
        height: "auto",
      },
    },
    profileMain: {
      background: "#FFFFFF",
      borderRadius: "0 0 12px 12px",
      padding: "46px 20px 40px",
      marginTop: "-5px",
      [theme.breakpoints.down("sm")]: {
        padding: 20,
      },
      "& .error": {
        color: "#f2545b",
        fontSize: 14,
      },
      "& .success": {
        color: "green",
        fontSize: 14,
      },
    },
    profileInnerMain: {
      maxWidth: 602,
      marginLeft: "auto",
      marginRight: "auto",
    },
    profileImg: {
      width: 96,
      height: 96,
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        width: 48,
        height: 48,
      },
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "inherit",
      },
    },
    profileUploadMai: {
      "& form": {
        display: "flex",
        alignItems: "center",
        marginBottom: 6,
      },
    },
    profileUpload: {
      margin: "0 10px 0 24px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 10px",
      },
      "& input": {
        display: "none",
      },
    },
    profileDelete: {
      background: "#EEF3F7",
      color: "#000",
      "&:hover": {
        background: "#EEF3F7",
      },
    },
    profileTitle: {
      margin: "24px 0",
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "24px",
      color: "#15131E",
    },
    profileUpdate: {
      "& label": {
        marginBottom: "0 !important",
      },
    },
    profilSubmitBtn: {
      textAlign: "right",
    },
    dividerGap: {
      margin: "24px 0",
    },
  }));
};

export const GreenChip = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    border: "1px solid #2DDE89",
    color: "#2DDE89",
    borderRadius: "4px",
  },
}))(Chip);

export const RedChip = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    border: "1px solid #EF4876",
    borderRadius: "4px",
    color: "#EF4876",
    fontSize: 10,
    fontWeight: 600,
  },
}))(Chip);

export const ArchiveChip = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    border: "1px solid #EF4876",
    borderRadius: "4px",
    color: "#EF4876",
    fontSize: 10,
    fontWeight: 600,
  },
}))(Chip);

export const WhiteBtn = withStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 20,
    padding: "8px 18px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#fff",
      opacity: "0.9",
      color: theme.palette.primary.main,
    },
    label: {
      color: "#0000",
    },
  },
}))(Button);

export const BlackChip = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: "#000",
    border: "1px solid",
    marginRight: 5,
    marginLeft: 5,
    textTransform: "Capitalize",
  },
}))(Chip);
export const WhiteChip = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: "#fff",
    border: "1px solid",
    margin: 5,
    textTransform: "Capitalize",
  },
}))(Chip);

export const LtOrangeChip = withStyles((theme) => ({
  root: {
    backgroundColor: "#FEEFED",
    color: "#F1624B",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 26,
  },
}))(Chip);

export const TimeChip = withStyles((theme) => ({
  root: {
    backgroundColor: "#EEF3F7",
    color: "#505B63",
    borderRadius: 8,
    padding: 8,
    height: 40,
    [theme.breakpoints.down("md")]: {
      marginBottom: 8,
    },
    "& svg": {
      color: "#505B63",
      marginLeft: 0,
    },
  },
}))(Chip);

export const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
      marginBottom: 20,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "#EEF3F7",
    marginBottom: -1,
    minHeight: 43,
    backgroundColor: "transparent",
    borderBottom: "1px solid #6D7A84",
    color: "#6D7A84",
    "& p": {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    "& .MuiAccordionSummary-expandIcon": {
      color: "#6D7A84",
    },
    "&$expanded": {
      minHeight: 43,
      color: "#f96634",
      borderBottom: "1px solid #F96634",
      "& .MuiAccordionSummary-expandIcon": {
        color: "#f96634",
      },
    },
  },
  content: {
    "&$expanded": {
      margin: "8px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "16px 0",
  },
}))(MuiAccordionDetails);
