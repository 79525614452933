import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { useState } from "react";
import { eventeerStyles } from "../../../../utils/hooks/useApplyStyles";
import AddIcon from "@material-ui/icons/Add";

const QnA = () => {
  const style = eventeerStyles();
  const classes = style();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box className={classes.qna}>
      <Container maxLength="lg">
        <Box className={classes.EventeerWrap}>
          <Typography
            variant="h2"
            align="center"
            className={classes.eventeerTitle}
          >
            Frequently Asked Questions
          </Typography>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
                How are winners decided?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                  Winners are decided by public voting. All nominees are
                  encouraged to promote their nomination and solicit votes from
                  contacts in their networks during the voting process.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
                When can I vote for my favorite events?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                  Voting will open on February 03, 2025, and close on February
                  10, 2025.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
                When will the winner be announced?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                  Winners will be announced on February 17, 2025.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className={classes.accordionMain}
          >
            <AccordionSummary
              expandIcon={
                <AddIcon fontSize="large" style={{ color: "#000" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.qnaTitle}>
                Will there be an Eventeer Award in 2026?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.qnaText}>
                <Typography>
                  Yes! We are already looking forward to celebrating the events'
                  successes of the year 2025 with everyone.
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
};

export default QnA;
